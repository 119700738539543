import {EthereumProvider} from "@walletconnect/ethereum-provider";
import walletIntegration from "@/wallet-integration";

export default class WalletConnectIntegration {
    #provider;

    async #createProvider() {
        let provider = null;
        try {
            provider = await EthereumProvider.init({
                projectId: process.env.VUE_APP_WALLET_CONNECT_PROJECT_ID,
                chains: [Number(walletIntegration.CHAIN_ID())], // REQUIRED chain ids
                showQrModal: true, // REQUIRED set to "true" to use @walletconnect/modal,
                // methods, // OPTIONAL ethereum methods
                // events, // OPTIONAL ethereum events
                // rpcMap, // OPTIONAL rpc urls for each chain
                // metadata, // OPTIONAL metadata of your app
                // qrModalOptions, // OPTIONAL - `undefined` by default
            });

            await provider.connect();

        } catch (e) {
            console.log('Error', e);
            const event = new CustomEvent('event-disconnect');
            window.dispatchEvent(event);
        }

        provider.on("chainChanged", (chainId) => {
            window.location.reload();
        });
        provider.on("accountsChanged", (accounts) => {
            window.location.reload();
        });
        // provider.on("connect", () => console.log('event-connect'));
        // provider.on("session_event", () => console.log('event-session_event'));
        // provider.on("display_uri", () => console.log('event-display_uri'));

        provider.on("disconnect", (code, reason) => {
            const event = new CustomEvent('event-disconnect', {detail: {reason}});
            window.dispatchEvent(event);
            // window.location.reload();
        });

        return provider;
    }

    async getProvider() {
        if (!this.#provider) {
            this.#provider = await this.#createProvider();
        }

        return this.#provider;
    }

    async connectAccounts(provider) {
        return await provider.enable().catch((error) => {
            console.log('Error:', error);
        });
    }
}
