<template>
    <v-app-bar app class="navbar-container" :height="displayAnnouncement?94:null">
        <div v-if="displayAnnouncement" class="announcement-panel d-flex align-center justify-center">
            <b class="mr-1">Open Beta</b> is available to play!
            <v-btn x-small color="warning" class="ml-2"
                   href="https://drive.google.com/drive/folders/1T306i-_Zw2WjKA9n7Gvmos2YvrEYHQ1K" target="_blank"
            >
                <v-icon>mdi-download</v-icon>
                Download here
            </v-btn>
        </div>

        <v-toolbar color="navbarBg" fixed elevation="0" class="navbar"
                   :class="{'announcement-margin':displayAnnouncement}">
            <router-link to="/" class="navbar-brand mr-8">
                <img class="pt-2 hidden-sm-and-down" src="/img/logo-mini.png" style="width: 130px;"
                     alt="Wizarre logo"/>
                <img class="pt-2 hidden-md-and-up" src="/img/logo-W-mini.png" style="width: 45px;"
                     alt="Wizarre logo"/>
            </router-link>

            <div v-for="menuPos in menuContent" :key="'te-'+menuPos.text">
                <v-menu v-if="menuPos.links && menuPos.links.length>0" dark offset-y open-on-hover>
                    <template v-slot:activator="{ on, attrs }">
                        <button
                            class="nav-item text-capitalize hidden-sm-and-down"
                            v-bind="attrs"
                            v-on="on"
                        >
                            {{ menuPos.text }}
                            <v-icon>mdi-chevron-down</v-icon>
                        </button>
                    </template>
                    <v-list class="hidden-sm-and-down">
                        <v-list-item
                            v-for="link in menuPos.links" :key="'l-'+link.routeName"
                            :to="{ name: link.routeName, params: {}}"
                            :class="link.class"
                            active-class="golden darken-1"
                        >
                            <v-list-item-title class="d-flex flex-row align-center">
                                <div class="nav-item-icon">
                                    <v-icon v-if="link.mdiIcon" small>{{ link.mdiIcon }}</v-icon>
                                    <div v-if="link.bgIcon" :class="link.bgIcon"></div>
                                    <div v-if="link.imgIcon"><img :src="link.imgIcon" alt=""/></div>
                                </div>
                                <div class="nav-item-text">
                                    {{ link.text }}
                                </div>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <div v-else>
                    <router-link
                        :to="{ name: menuPos.routeName, params: {}}"
                        class="text-decoration-none hidden-sm-and-down"
                    >
                        <button class="nav-item text-capitalize">
                            {{ menuPos.text }}
                        </button>
                    </router-link>
                </div>
            </div>

            <v-spacer/>
            <wallet-button></wallet-button>
            <v-btn
                v-if="isWalletConnected"
                :to="{ name: 'Profile', params: {}}"
                class="ml-5"
                :disabled="!userAccount.username"
                :loading="!userAccount.username"
            >
                <v-img class="avatar-img" :src="avatarSrc"/>
                <span class="d-none d-md-block">
                    Profile
                </span>
            </v-btn>

            <v-spacer class="hidden-md-and-up"/>
            <v-app-bar-nav-icon @click="drawer = !drawer"
                                class="d-flex d-lg-none hidden-sm-and-up"
                                style="background: none;"
            />
        </v-toolbar>

        <v-navigation-drawer
            width="80%"
            v-model="drawer"
            absolute
            temporary
            height="100vh"
            color="background"
        >
            <v-list nav dense color="navbarBg">
                <v-list-item-group>
                    <v-list-item>
                        <router-link to="/" class="navbar-brand mr-4">
                            <img alt="Wizarre logo" class="pt-2" src="/img/logo-mini.png" style="width: 130px;"/>
                        </router-link>
                    </v-list-item>

                    <div class="d-flex justify-start flex-wrap-reverse">
                        <div>
                            <wallet-balance-web3/>
                            <v-btn
                                :to="{ name: 'BuyToken', params: {}}"
                                class="mb-2 ml-4"
                                color="golden"
                                outlined
                                small
                            >
                                <div class="bg-menu_icon_buy_scrl mr-2"></div>
                                Get SCRL
                            </v-btn>
                        </div>
                        <wallet-balance-virtual/>
                        <v-spacer></v-spacer>
                    </div>

                    <v-divider></v-divider>

                    <div v-for="menuPos in menuContent" :key="'tm-'+menuPos.text">
                        <v-subheader v-if="menuPos.links && menuPos.links.length>0"
                                     class="white--text font-weight-bold">
                            ↓ {{ menuPos.text }}
                        </v-subheader>
                        <v-subheader v-else class="white--text font-weight-bold">
                            <router-link
                                :to="{ name: menuPos.routeName, params: {}}"
                                class="text-decoration-none"
                            >
                                {{ menuPos.text }}
                            </router-link>
                        </v-subheader>
                        <v-list-item
                            v-for="link in menuPos.links" :key="'l-'+link.routeName"
                            :to="{ name: link.routeName, params: {}}"
                            :class="link.class"
                        >
                            <v-list-item-title class="d-flex flex-row align-center">
                                <div class="nav-item-icon">
                                    <v-icon v-if="link.mdiIcon" dense>{{ link.mdiIcon }}</v-icon>
                                    <div v-if="link.bgIcon" :class="link.bgIcon"></div>
                                    <div v-if="link.imgIcon"><img :src="link.imgIcon" alt=""/></div>
                                </div>
                                <div class="nav-item-text">
                                    {{ link.text }}
                                </div>
                            </v-list-item-title>
                        </v-list-item>
                    </div>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>

    </v-app-bar>
</template>

<script>
import WalletButton from "./walletButton";
import _ from "lodash";
import WalletBalanceWeb3 from "@/components/wallet-balance-web3";
import WalletBalanceVirtual from "@/components/wallet-balance-virtual";
import PermissionHelper from "@/helpers/permissionHelper";

export default {
    components: {WalletBalanceVirtual, WalletBalanceWeb3, WalletButton},
    data: () => ({
        displayAnnouncement: false,
        drawer: false,
        menu: [
            {
                text: 'Treasury',
                links: [
                    {routeName: 'MyWizards', text: 'My Wizards', bgIcon: 'bg-menu_icon_my_wizards'},
                    {routeName: 'MyLands', text: 'My Lands', bgIcon: 'bg-menu_icon_my_lands'},
                    {routeName: 'MyEnhancements', text: 'Bottomless bag', bgIcon: 'bg-menu_icon_bottomless_bag'},
                    {routeName: 'MyCards', text: 'My Cards', bgIcon: 'bg-menu_icon_my_cards'},
                ]
            },
            {
                text: 'Places',
                links: [
                    {routeName: 'Marketplace', text: 'Marketplace', bgIcon: 'bg-menu_icon_marketplace'},
                    {routeName: 'Summoning', text: 'Summoning Temple', bgIcon: 'bg-menu_icon_summoning_temple'},
                    {routeName: 'Map', text: 'World Map', bgIcon: 'bg-menu_icon_world_map'},
                    {routeName: 'StakingMap', text: 'Staking Map', bgIcon: 'bg-menu_icon_staking_map'},
                ]
            },
            {
                text: 'Bank',
                links: [
                    {routeName: 'BuyToken', text: 'Get SCRL', bgIcon: 'bg-menu_icon_buy_scrl'},
                    {routeName: 'ContractInfo', text: 'Contract Info', bgIcon: 'bg-menu_info_contract_info'},
                    // Legacy:
                    // {routeName: 'LandLottery', text: 'Land Lottery', bgIcon: 'bg-menu_icon_my_lands'},
                    // {routeName: 'WizardStaking', text: 'Wizard Staking', bgIcon: 'bg-menu_icon_wizard_staking'},
                ]
            },
            {
                text: 'Wizarropedia',
                links: [
                    {routeName: 'WizarropediaLands', text: 'Lands', bgIcon: 'bg-menu_icon_my_lands'},
                    {routeName: 'WizarropediaEnhancements', text: 'Enhancements', bgIcon: 'bg-menu_chest'},
                    {routeName: 'WizarropediaSpells', text: 'Spells', bgIcon: 'bg-menu_icon_my_wizards'},
                ]
            },
            // {text: 'Staking Map', routeName: 'StakingMap', bgIcon: 'bg-menu_icon_staking_map',},
            {text: 'Leaderboard', routeName: 'Leaderboard', bgIcon: '',},
        ],
    }),
    mounted() {
    },
    computed: {
        web3Balance() {
            return this.web3.balance;
        },
        web3() {
            return this.$store.state.web3;
        },
        isWalletConnected() {
            return this.web3.isWalletConnected;
        },
        userAccount() {
            return this.$store.state.userAccount;
        },
        avatarSrc() {
            return this.userAccount.avatar ? '/img/profile/avatars/' + this.userAccount.avatar + '.jpg' : '';
        },
        menuContent() {
            let menuMemory = _.cloneDeep(this.menu);

            if (this.isVesting) {
                menuMemory[2].links.push(
                    {routeName: 'ClaimToken', text: 'Claim SCRL', bgIcon: 'bg-menu_icon_claim_scrl'},
                )
            }

            if (this.isSage) {
                menuMemory[2].links.push(
                    {routeName: 'ClaimTokenSage', text: 'Claim SCRL (Sage)', bgIcon: 'bg-menu_icon_claim_scrl'},
                )
            }

            if (this.isTeam) {
                menuMemory[2].links.push(
                    {routeName: 'ClaimTokenTeam', text: 'Claim SCRL (Team)', bgIcon: 'bg-menu_icon_claim_scrl'},
                );
            }

            if (this.isAdmin) {
                menuMemory.push(
                    {
                        text: 'Admin',
                        links: [
                            {routeName: 'AdminDashboard', text: 'Dashboard', mdiIcon: 'mdi-monitor-dashboard'},
                            {routeName: 'AdminContests', text: 'Contests', mdiIcon: 'mdi-book-cog-outline'},
                        ]
                    }
                );
            }

            return menuMemory;
        },
        isAdmin() {
            if (this.isWalletConnected && this.userAccount.username) {
                let route = this.$router.getRoutes().find(x => x.name === 'Admin');
                return route.beforeEnter(route, () => {
                }, () => {
                });
            }
            return false;
        },
        isVesting() { // user vesting from launchpad sale
            const allowOnlyVesting = (to, from, next) => {
                return PermissionHelper.allowOnlyFor(to, from, next, 'ClaimTokenVesting');
            }
            if (this.isWalletConnected) {
                let route = this.$router.getRoutes().find(x => x.name === 'ClaimToken');
                return allowOnlyVesting(route, () => {
                }, () => {
                });
            }
            return false;
        },
        isSage() { // advisor
            if (this.isWalletConnected) {
                let route = this.$router.getRoutes().find(x => x.name === 'ClaimTokenSage');
                return route.beforeEnter(route, () => {
                }, () => {
                });
            }
            return false;
        },
        isTeam() {
            if (this.isWalletConnected) {
                let route = this.$router.getRoutes().find(x => x.name === 'ClaimTokenTeam');
                return route.beforeEnter(route, () => {
                }, () => {
                });
            }
            return false;
        }
    },
    methods: {}
}
</script>

<style lang="scss" scoped>
.avatar-img {
    width: 24px;
    height: 24px;
    margin: 0 5px 0 -5px;
    border: 1px solid #000;
    border-radius: 5px;
}

.announcement-panel {
    width: 100%;
    height: 30px;
    position: fixed;
    top: 0;
    z-index: 101;
    display: block;
    background: var(--v-secondary-base);
    margin: 0 0 0 -16px;
    color: #000;
}

.announcement-margin {
    margin-top: 30px;
}

.navbar-container {
    z-index: 100 !important;
    background: var(--v-navbarBg-base) !important;
}

.navbar,
.v-list {
    background: var(--v-buttonBg-base);
}

.router-link-active {
    .nav-item {
        background: var(--v-golden-darken1);

        &:hover {
            border-bottom-color: var(--v-buttonBg-base);
            background: var(--v-golden-base);
        }
    }
}

.nav-item {
    width: auto;
    height: 64px;
    font-family: 'STIX Two Text', serif;
    font-size: 20px;
    padding: 4px 20px 0 10px;
    display: block;
    border-bottom: 4px solid transparent;

    &:hover {
        border-bottom-color: var(--v-golden-base);
        background: var(--v-buttonBg-base);
    }
}

.v-list-item {
    &:hover {
        .nav-item-text {
            color: var(--v-golden-base);
        }

        .nav-item-icon div {

        }
    }
}

.nav-item-icon {
    width: 18px;
    height: 24px;
    display: block;
    overflow: hidden;
    text-align: center;
}

.nav-item-text {
    display: block;
    margin-left: 5px;
    margin-top: 1px;
}

.bg-menu_icon_bottomless_bag {
    width: 15px;
    height: 18px;
    background: url('/img/icons/common-icons.png') -10px -95px;
}

.bg-menu_icon_buy_scrl {
    width: 15px;
    height: 18px;
    background: url('/img/icons/common-icons.png') -45px -95px;
}

.bg-menu_icon_claim_scrl {
    width: 15px;
    height: 16px;
    background: url('/img/icons/common-icons.png') -80px -95px;
}

.bg-menu_icon_marketplace {
    width: 15px;
    height: 21px;
    background: url('/img/icons/common-icons.png') -45px -10px;
}

.bg-menu_icon_my_cards {
    width: 15px;
    height: 21px;
    background: url('/img/icons/common-icons.png') -80px -10px;
}

.bg-menu_icon_my_lands {
    width: 15px;
    height: 21px;
    background: url('/img/icons/common-icons.png') -10px -54px;
}

.bg-menu_icon_my_wizards {
    width: 15px;
    height: 20px;
    background: url('/img/icons/common-icons.png') -115px -51px;
}

.bg-menu_icon_staking_map {
    width: 15px;
    height: 21px;
    background: url('/img/icons/common-icons.png') -45px -54px;
}

.bg-menu_icon_summoning_temple {
    width: 15px;
    height: 21px;
    background: url('/img/icons/common-icons.png') -80px -54px;
}

.bg-menu_icon_wizard_staking {
    width: 15px;
    height: 16px;
    background: url('/img/icons/common-icons.png') -115px -95px;
}

.bg-menu_icon_world_map {
    width: 15px;
    height: 24px;
    background: url('/img/icons/common-icons.png') -10px -10px;
}

.bg-menu_info_contract_info {
    width: 15px;
    height: 21px;
    background: url('/img/icons/common-icons.png') -115px -10px;
}

.bg-menu_chest {
    width: 24px;
    height: 24px;
    background: url('/img/staking/icon-chest.svg') center center no-repeat;
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(331deg) brightness(100%) contrast(100%);
}
</style>

<style lang="scss">
.announcement-panel {
    a,
    .v-btn__content,
    .v-icon {
        color: #000 !important;
    }
}
</style>
