import Web3 from 'web3';
import BigNumber from "bignumber.js";
import TokenContract, {tokenContractDecimals} from "./services/contracts/tokenContract";
import {NoWalletError, WalletError, WalletErrorHandler, WrongNetworkError} from "./errors/WalletErrors";
import WalletConnectIntegration from "@/walletConnectIntegration";

BigNumber.config({EXPONENTIAL_AT: 78});

export default new class WalletIntegration {
    DECIMALS = null;
    web3 = null;
    account = null;
    tokenContract = null;

    constructor() {
        this.DECIMALS = (new BigNumber(10)).pow(tokenContractDecimals);
    }

    CHAIN_ID() {
        return process.env.VUE_APP_BLOCKCHAIN_CHAIN_ID;
    }

    async initMetamaskWeb3() {
        if (this.isWeb3Installed()) {
            this.web3 = new Web3(window.ethereum);
            const wrongChainId = !(await this.isCurrentChainIdOk());
            if (wrongChainId) {
                // Display popup asking to change network
                const event = new CustomEvent('event-wrong-network', {});
                window.dispatchEvent(event);
                throw new WrongNetworkError();
            }

            window.ethereum.on('connect', (ConnectInfo) => {
                //Doesn't work
                console.log('on-connect');
                // const event = new CustomEvent('event-connect', {});
                // window.dispatchEvent(event);
            });

            window.ethereum.on('disconnect', (accounts) => {
                console.log('on-disconnect')
                const event = new CustomEvent('event-disconnect', {});
                window.dispatchEvent(event);
            });

            window.ethereum.on('accountsChanged', (accounts) => {
                console.log('on-accountsChanged', accounts);
                if (accounts.length > 1) {
                    // Single account - connected
                    // More accounts - switch account
                    window.location.reload();
                } else if (0 === accounts.length) {
                    const event = new CustomEvent('event-disconnect', {});
                    window.dispatchEvent(event);
                    window.location.reload();
                }
            });

            window.ethereum.on('chainChanged', (chainId) => {
                console.log('on-chainChanged', chainId);
                window.location.reload();
            });
        } else {
            console.log('No wallet connected');
        }
    }

    async isCurrentChainIdOk() {
        let currentChain;
        try {
            currentChain = await window.ethereum.request({method: 'eth_chainId'});
        } catch (e) {
            // act like selected chain is wrong
        }
        return this.CHAIN_ID() === currentChain;
    }

    async switchUserNetwork() {
        return window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{chainId: this.CHAIN_ID()}],
        });
    }

    async addUserNetwork() {
        return window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [{
                chainId: this.CHAIN_ID(),
                chainName: 'Binance Smart Chain: ' + this.CHAIN_ID(),
                nativeCurrency: {
                    name: 'BNB',
                    symbol: 'BNB',
                    decimals: 18,
                },
                rpcUrls: [
                    process.env.VUE_APP_BLOCKCHAIN_RPC_URL
                ],
                blockExplorerUrls: [
                    process.env.VUE_APP_BLOCKCHAIN_BLOCK_EXPLORER_URL
                ],
            }],
        });
    }

    async connectMetamask() {
        try {
            this.validateWeb3Installed();
            await this.initMetamaskWeb3();
            window.ethereum.request({method: 'eth_requestAccounts'}) //must be executed before getting account
                .then(async () => {
                    const accounts = await this.web3.eth.getAccounts();
                    await this.connectToTokenContract(this.web3, accounts.at(0));
                    this.setConnectedWallet('MM');
                })
                .catch((error) => {
                    this.setConnectedWallet(null);
                    WalletErrorHandler.handleTxError(error);
                });
        } catch (e) {
            if (e instanceof WalletError) {
                //continue
            } else {
                throw (e);
            }
        }
    }

    async connectWalletConnect() {
        try {
            const wcIntegration = new WalletConnectIntegration();
            const wcProvider = await wcIntegration.getProvider();

            this.web3 = new Web3(wcProvider);
            const accounts = await wcIntegration.connectAccounts(wcProvider);

            await this.connectToTokenContract(this.web3, accounts.at(0));
            this.setConnectedWallet('WC');
        } catch (err) {
            console.log('WC-Error:', err);
            this.disconnect();
        }
    }

    setConnectedWallet(walletName) {
        if (walletName) {
            localStorage.setItem('connectedWallet', walletName);
        } else {
            localStorage.removeItem('connectedWallet');
        }
        const event = new CustomEvent('event-set-connected-wallet');
        window.dispatchEvent(event);
    }

    getConnectedWallet() {
        return localStorage.getItem('connectedWallet');
    }

    disconnect(reload = true) {
        if ('WC' === this.getConnectedWallet()) {
            this.web3.currentProvider.disconnect();
        }
        this.setConnectedWallet(null);
        if (reload) {
            window.location.reload();
        }
    }

    async connectToTokenContract(web3, account) {
        this.account = account;

        // Token
        const tokenContractClass = new TokenContract(web3, account);
        const tokenContract = await tokenContractClass.getTokenContract();
        const balance = await tokenContractClass.getCurrentAccountBalance();

        const event = new CustomEvent('wallet-connected', {
            detail: {
                balance: balance,
                address: this.getUserAddress(),
                eth: this.web3.eth,
                tokenContract: tokenContract,
            }
        });
        window.dispatchEvent(event);
    }

    isWeb3Installed() {
        return typeof window.ethereum !== 'undefined';
    }

    validateWeb3Installed() {
        if (!this.isWeb3Installed()) {
            const event = new CustomEvent('event-no-web3-wallet', {});
            window.dispatchEvent(event);
            throw new NoWalletError();
        }
    }

    getUserAddress() {
        const urlPersonParam = new URL(location.href).searchParams.get('person');
        if (urlPersonParam && this.isAddress(urlPersonParam)) {
            console.log('Impersonating: ' + urlPersonParam);
            return urlPersonParam;
        }
        return this.account;
    }

    hashTextKeccak256(text) {
        return Web3.utils.keccak256(text);
    }

    isAddress(string) {
        return Web3.utils.isAddress(string);
    }

    personalSign(text) {
        return this.web3.eth.personal.sign(text, this.getUserAddress(), '');
    }
}

