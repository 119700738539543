import router from "@/router";

export default class PermissionHelper {
    static allowOnlyFor(to, from, next, permissionName) {
        try {
            const web3 = router.app.$store.state.web3;
            const userAccount = router.app.$store.state.userAccount;
            if (web3.isWalletConnected && userAccount.permissions.includes(permissionName)) {
                next();
                return true;
            } else {
                next({name: 'Home'});
                return false;
            }
        } catch (e) {
            next({name: 'Home'});
            return false;
        }
    }
}
