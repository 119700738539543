<template>
    <v-app :class="routeNameClass">
        <wallet-dialogs/>

        <navigation-bar-condensed/>

        <FlashMessage :position="'right bottom'" style="z-index: 100"></FlashMessage>
        <v-main>
            <router-view/>
        </v-main>

        <footer-bar/>

    </v-app>
</template>

<script>
import WalletIntegration from './wallet-integration';
import FooterBar from "./components/footer-bar";
import WalletDialogs from "./components/wallet/wallet-dialogs";
import NavigationBarCondensed from "./components/navigation-bar-condensed";

// Windows clarity
if (process.env.NODE_ENV === 'production') {
    // @formatter:off
    // (function(c,l,a,r,i,t,y){
    //     c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
    //     t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
    //     y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    // })(window, document, "clarity", "script", "mkp0aspmv7");
    // @formatter:on
}

export default {
    components: {NavigationBarCondensed, WalletDialogs, FooterBar},
    data() {
        return {
            walletRefreshInterval: undefined,
        }
    },
    async mounted() {
        this.setWeb3Watchers();

        const connectedWallet = WalletIntegration.getConnectedWallet();
        if ('MM' === connectedWallet) { // Metamask
            await WalletIntegration.connectMetamask();
        } else if ('WC' === connectedWallet) { // WalletConnect
            await WalletIntegration.connectWalletConnect();
        }
        this.walletRefreshInterval = setInterval(() => {
            this.$store.dispatch('updateWeb3Balance');
            this.$store.dispatch('updateVirtualBalance');
        }, 5 * 60 * 1000);  // 5min
        window.addEventListener('user-account-loaded', this.welcomeUser);
    },
    destroyed() {
        clearInterval(this.walletRefreshInterval);
        window.removeEventListener('user-account-loaded', this.welcomeUser);
    },
    computed: {
        routeNameClass() {
            return (this.$route && this.$route.name) ? 'route-' + this.$route.name.toLowerCase() : '';
        }
    },
    methods: {
        setWeb3Watchers() {
            this.$store.dispatch('startWeb3');
        },
        welcomeUser() {
            this.flashMessage.show({
                status: 'info',
                message: `Welcome ${this.$store.state.userAccount.username}`
            })
        },
    }
}
</script>

<style lang="scss">
@import 'scss/app.scss';

#app {
    background: var(--v-navbarBg-base) url('/img/bg-mandala.png') 50% 160px repeat-y;
    background-size: 80%;

    &.route-home {
        background: var(--v-navbarBg-base) url('/img/home/home-bg.jpg') 50% 0 no-repeat;
        background-size: cover;
    }

    &.route-marketplace {
        background: var(--v-marketBg-base) url('/img/market/mandala-marketplace.png') 200px -250px repeat-y;
        background-size: 100%;
    }

    &.route-map {
        background: var(--v-navbarBg-base) url('/img/map/map-bg.png') 50% 50%;
        background-size: cover;
    }

    &.route-wizarropedialands,
    &.route-wizarropediaenhancements,
    &.route-wizarropediaspells,
    {
        background: var(--v-marketBg-base) url('/img/market/mandala-marketplace.png') 200px -250px repeat-y;
        background-size: 100%;
    }

}

::v-deep .v-card__title,
::v-deep .v-dialog > .v-card > .v-card__title {
    font-family: 'Sansita', sans-serif;
    font-weight: normal;
    text-transform: capitalize;
    font-size: 40px;
}

.v-btn__content {
    color: #f8c015;
}

</style>
