var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "dialog-box",
        {
          attrs: {
            model: _vm.unsupportedChainDialog,
            persistent: "",
            width: "800",
            title: "Unsupported network",
          },
        },
        [
          _c("br"),
          _vm._v(" Your wallet is currently using an unsupported network."),
          _c("br"),
          _vm._v(" Change to "),
          _c("b", [
            _vm._v("Binance Smart Chain (" + _vm._s(_vm.chainName) + ")"),
          ]),
          _vm._v(" network "),
          _c(
            "div",
            { staticClass: "mt-10 text-center" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-10",
                  on: {
                    click: function ($event) {
                      return _vm.switchNetwork()
                    },
                  },
                },
                [_vm._v(" Switch to BSC Network ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mr-10",
                  on: {
                    click: function ($event) {
                      return _vm.addNetwork()
                    },
                  },
                },
                [_vm._v(" Add BSC Network ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "" },
                  on: {
                    click: function ($event) {
                      return _vm.disconnect()
                    },
                  },
                },
                [_vm._v(" Disconnect ")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "dialog-box",
        {
          attrs: {
            model: _vm.noWeb3Wallet,
            persistent: "",
            width: "700",
            title: "No Web3 wallet installed",
          },
        },
        [
          _c("br"),
          _vm._v(
            " To continue, please install a Web3 wallet browser plugin. We recommend using MetaMask "
          ),
          _c(
            "div",
            { staticClass: "mt-10 text-center" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-10",
                  attrs: {
                    href: "https://metamask.io/download/",
                    target: "_blank",
                  },
                },
                [_vm._v(" Install MetaMask ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mr-10",
                  on: {
                    click: function ($event) {
                      _vm.noWeb3Wallet = false
                    },
                  },
                },
                [_vm._v(" Exit ")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "dialog-box",
        {
          attrs: {
            model: _vm.disconnectedDialog,
            persistent: "",
            width: "800",
            title: "Disconnected wallet",
          },
        },
        [
          _c("br"),
          _vm._v(" Your wallet has been disconnected "),
          _c(
            "div",
            { staticClass: "mt-10 text-center" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-10",
                  on: {
                    click: function ($event) {
                      return _vm.disconnectedOkClick()
                    },
                  },
                },
                [_vm._v(" Ok ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }