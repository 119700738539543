var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-footer",
    {
      staticClass: "d-flex flex-column align-stretch px-8 py-2",
      attrs: {
        app: "",
        absolute: "",
        color: "rgba(56,41,74,0.5)",
        elevation: "15",
      },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "mb-1 d-flex flex-wrap justify-space-between align-center",
        },
        [
          _c(
            "router-link",
            { staticClass: "links-container", attrs: { to: "/" } },
            [
              _c("img", {
                attrs: {
                  alt: "Wizarre logo",
                  height: "55px",
                  src: "/img/logo-mini.png",
                },
              }),
            ]
          ),
          _c(
            "div",
            { staticClass: "social-links" },
            _vm._l(_vm.socialLinks, function (socialLink, i) {
              return _c(
                "v-btn",
                {
                  key: `social_${i}`,
                  staticClass: "ml-0 ml-sm-2 mr-2 mr-sm-0",
                  attrs: {
                    fab: "",
                    small: "",
                    href: socialLink.link,
                    title: socialLink.title,
                    target: "_blank",
                  },
                },
                [
                  socialLink.icon
                    ? _c("v-icon", { staticClass: "mdi-24px" }, [
                        _vm._v(_vm._s(socialLink.icon)),
                      ])
                    : _vm._e(),
                  socialLink.img
                    ? _c("img", {
                        attrs: {
                          width: "25px",
                          src: socialLink.img,
                          alt: socialLink.title,
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-wrap align-md-start justify-space-between justify-md-start mr-2 mr-sm-0",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-column align-baseline align-self-end links-container",
            },
            _vm._l(_vm.tradeLinks, function (tradeLink, i) {
              return _c(
                "a",
                {
                  key: `trade_${i}`,
                  staticClass: "text--secondary text-none d-flex align-center",
                  attrs: {
                    href: tradeLink.link,
                    title: tradeLink.title,
                    target: "_blank",
                  },
                },
                [
                  _c("img", {
                    staticClass: "mr-1",
                    attrs: {
                      height: "15px",
                      src: tradeLink.img,
                      alt: tradeLink.title,
                    },
                  }),
                  _vm._v(" " + _vm._s(tradeLink.text) + " "),
                ]
              )
            }),
            0
          ),
          _c(
            "div",
            {
              staticClass: "d-flex flex-column align-baseline links-container",
            },
            _vm._l(_vm.tokenLinks, function (tokenLink, i) {
              return _c(
                "a",
                {
                  key: `token_${i}`,
                  staticClass: "text--secondary text-none d-flex align-center",
                  attrs: {
                    href: tokenLink.link,
                    title: tokenLink.title,
                    target: "_blank",
                  },
                },
                [
                  _c("img", {
                    staticClass: "mr-1",
                    attrs: {
                      height: "15px",
                      src: tokenLink.img,
                      alt: tokenLink.title,
                    },
                  }),
                  _vm._v(" " + _vm._s(tokenLink.text) + " "),
                ]
              )
            }),
            0
          ),
          _c(
            "div",
            {
              staticClass:
                "ml-md-auto align-self-end d-flex flex-column align-md-end",
            },
            [
              _c("div", [
                _c(
                  "a",
                  {
                    staticClass: "text--primary",
                    attrs: {
                      href: "https://www.wizarre.io/privacy-policy",
                      target: "_blank",
                    },
                  },
                  [
                    _vm._v("Privacy Policy "),
                    _c("v-icon", { attrs: { "x-small": "" } }, [
                      _vm._v("mdi-open-in-new"),
                    ]),
                  ],
                  1
                ),
                _vm._v(" | "),
                _c(
                  "a",
                  {
                    staticClass: "text--primary",
                    attrs: {
                      href: "https://www.wizarre.io/eula",
                      target: "_blank",
                    },
                  },
                  [
                    _vm._v("EULA "),
                    _c("v-icon", { attrs: { "x-small": "" } }, [
                      _vm._v("mdi-open-in-new"),
                    ]),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "text--secondary" }, [
                _vm._v(" © 2024 Wizarre. All rights reserved. "),
              ]),
            ]
          ),
        ]
      ),
      _c("div", { staticClass: "download-section" }, [
        _c("div", { staticClass: "d-flex flex-row justify-center" }, [
          _c("div", [
            _c(
              "a",
              {
                attrs: {
                  href: "https://play.google.com/store/apps/details?id=com.FlyingCactus.Wizarre&referrer=utm_source%3Dapp%26utm_medium%3Dfooter",
                  target: "_blank",
                },
              },
              [
                _c("img", {
                  attrs: { src: "/img/download-game/google-play-badge.png" },
                }),
              ]
            ),
          ]),
          _c("div", [
            _c(
              "a",
              {
                attrs: {
                  href: "https://cdn.wizarre.io/wizarre-1.6.0.zip",
                  target: "_blank",
                },
              },
              [
                _c("img", {
                  attrs: { src: "/img/download-game/download-windows.png" },
                }),
              ]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }