var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "main-button main-button-shadow mr-2",
      class: { big: _vm.big, glow: _vm.glow, disabledBtn: _vm.disabled },
    },
    [
      _c(
        "v-btn",
        {
          staticClass: "main-button button d-flex align-center justify-center",
          class: {
            big: _vm.big,
          },
          attrs: {
            ripple: false,
            disabled: _vm.disabled,
            loading: _vm.loading,
            small: _vm.small,
          },
          on: {
            click: function ($event) {
              return _vm.onClick()
            },
          },
        },
        [_vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }