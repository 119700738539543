import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: true,
        options: {
            customProperties: true,
        },
        themes: {
            dark: {
                golden: '#f9c017',
                purple: '#49335b',
                primary: '#48325b',
                secondary: '#f8c015',
                accent: '#FF4081',
                error: '#FF5252',
                info: '#2196F3',
                warning: '#FB8C00',
                background: '#221529',
                cardBg: '#38294a',
                navbarBg: '#38294a',
                buttonBg: '#4c356d',
                frames: '#b59365',
                marketBg: '#1c272f',
                marketTab: '#384d5c',
            },
        },
    }
});
