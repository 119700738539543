import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import {tokenContractDecimals} from "./services/contracts/tokenContract";
import vTitle from 'vuejs-title'
import FlashMessage from '@smartweb/vue-flash-message';
import vueMoment from 'vue-moment';
import LoadScript from 'vue-plugin-load-script';
import VueGtm from '@gtm-support/vue2-gtm';

const isProduction = process.env.NODE_ENV === 'production';

Vue.config.productionTip = false;

Vue.use(vueMoment);

//---Google analytics (GTag)---
Vue.use(VueGtm, {
    id: 'GTM-M825DCB',
    defer: true,
    compatibility: true,
    enabled: isProduction,
    debug: false,
    loadScript: true,
    vueRouter: router,
    ignoredViews: [],
    trackOnNextTick: false,
});

//https://github.com/fattihkoca/vuejs-title
Vue.use(vTitle, {
    transitionDelay: 0,
    transitionDuration: 100
})

//https://github.com/smwbtech/vue-flash-message
Vue.use(FlashMessage, {
    time: 8000,
    strategy: 'multiple'
});

//Add external scripts do Vue. Use for BuyToken->Swipelux widget
Vue.use(LoadScript);

Vue.filter('shortAddress', function (value) {
    if (value && value.length > 1) {
        return value?.substring(0, 4) + '...' + value?.substring(value.length - 3, value.length);
    }
    return null;
});

Vue.filter('addDecimals', function (value) {
    return Math.round(Number(value) * (10 ** tokenContractDecimals));
});

Vue.filter('removeDecimals', function (value) {
    return Math.round(Number(value) / (10 ** tokenContractDecimals));
});

Vue.filter('localFormatNumber', function (value) {
    const number = Number(value);
    return new Intl.NumberFormat(undefined, {style: 'decimal', maximumFractionDigits: 0}).format(number);
});

Vue.filter('localDateTime', function (value) {
    return new Date(value).toLocaleString();
});

new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount("#app");
