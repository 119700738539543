export class WalletErrorHandler {
    static #WALLET_ERROR_CODES = {
        userRejectedRequest: 4001,
        unauthorized: 4100,
        unsupportedMethod: 4200,
        disconnected: 4900,
        chainDisconnected: 4901,
        unauthorizedChain: 4902,
        missingNode: -32000,
        resourceUnavailable: -32002,
    }

    static handleTxError(error) {
        switch (error?.code) {
            case this.#WALLET_ERROR_CODES.resourceUnavailable:
                throw new UserResourceUnavailable();
            case this.#WALLET_ERROR_CODES.userRejectedRequest:
                throw new UserRejectedTransactionError();
            case this.#WALLET_ERROR_CODES.unauthorized:
                throw new UserUnauthorizedError();
            case this.#WALLET_ERROR_CODES.unauthorizedChain:
                throw new UnauthorizedChainError();
            case this.#WALLET_ERROR_CODES.missingNode:
                throw new WalletError();
        }

        throw error;
    }
}


export class WalletError extends Error {
    name = this.constructor.name;
}

export class NoWalletError extends WalletError {
}

export class WrongNetworkError extends WalletError {
}

//Codes: https://github.com/MetaMask/eth-rpc-errors/blob/main/src/error-constants.ts
export class UserWalletError extends WalletError {
}

export class UserRejectedTransactionError extends UserWalletError {
}

export class UserUnauthorizedError extends UserWalletError {
}

export class UserResourceUnavailable extends UserWalletError {
}

export class UnauthorizedChainError extends UserWalletError {
}
