var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "balance-indicator mt-1" }, [
    _c("img", {
      attrs: {
        height: "26px",
        src: "/img/tokens/v-scroll_48.png",
        width: "26px",
      },
    }),
    _c("span", { staticClass: "pr-3 pl-1", attrs: { id: "balance" } }, [
      _vm._v(_vm._s(_vm.formattedBalance)),
    ]),
    _c("span", [_vm._v("vSCRL")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }