var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "balance-indicator mt-1" }, [
    _c("img", {
      attrs: { height: "26px", src: "/img/scroll_48.png", width: "26px" },
      on: {
        click: function ($event) {
          return _vm.addTokenToProvider()
        },
      },
    }),
    _c("span", { staticClass: "pr-3 pl-1", attrs: { id: "balance" } }, [
      _vm._v(_vm._s(_vm.formattedBalance)),
    ]),
    _c("span", [_vm._v("SCRL")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }