<template>
    <div class="web3-wallet-button">
        <v-btn v-if="!isWalletConnected" color="primary"
               class="text-capitalize wallet-btn"
               @click.stop="walletChooseDialogOpen=!walletChooseDialogOpen"
        >
            Connect Web3 wallet
        </v-btn>

        <dialog-box
            v-if="!isWalletConnected"
            title="Connect Web3 Wallet"
            :model="walletChooseDialogOpen"
            @dialogClosed="walletChooseDialogOpen=!walletChooseDialogOpen"
            width="auto"
        >
            <v-row>
                <v-col md="12" class="d-flex flex-row justify-center align-center pt-10">
                    <h4 class="text-center">
                        We are on
                    </h4>
                    <img src="/img/bnb-logo.png" alt="Binance Smart Chain" class="ml-3"/>
                </v-col>
            </v-row>
            <v-row class="mt-8 mr-2">
                <!-- Metamask -->
                <v-col md="6">
                    <v-btn
                        color="primary"
                        class="text-capitalize wallet-btn"
                        @click="connectMetamaskWallet()"
                        x-large
                        block
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 318.6 318.6" stroke-linejoin="round"
                             class="mr-2" style="height:30px;">
                            <path d="M274.1 35.5l-99.5 73.9L193 65.8z" fill="#e2761b" stroke="#e2761b"/>
                            <g fill="#e4761b" stroke="#e4761b">
                                <path
                                    d="M44.4 35.5l98.7 74.6-17.5-44.3zm193.9 171.3l-26.5 40.6 56.7 15.6 16.3-55.3zm-204.4.9L50.1 263l56.7-15.6-26.5-40.6z"/>
                                <path
                                    d="M103.6 138.2l-15.8 23.9 56.3 2.5-2-60.5zm111.3 0l-39-34.8-1.3 61.2 56.2-2.5zM106.8 247.4l33.8-16.5-29.2-22.8zm71.1-16.5l33.9 16.5-4.7-39.3z"/>
                            </g>
                            <path d="M211.8 247.4l-33.9-16.5 2.7 22.1-.3 9.3zm-105 0l31.5 14.9-.2-9.3 2.5-22.1z"
                                  fill="#d7c1b3" stroke="#d7c1b3"/>
                            <path d="M138.8 193.5l-28.2-8.3 19.9-9.1zm40.9 0l8.3-17.4 20 9.1z" fill="#233447"
                                  stroke="#233447"/>
                            <path
                                d="M106.8 247.4l4.8-40.6-31.3.9zM207 206.8l4.8 40.6 26.5-39.7zm23.8-44.7l-56.2 2.5 5.2 28.9 8.3-17.4 20 9.1zm-120.2 23.1l20-9.1 8.2 17.4 5.3-28.9-56.3-2.5z"
                                fill="#cd6116" stroke="#cd6116"/>
                            <path
                                d="M87.8 162.1l23.6 46-.8-22.9zm120.3 23.1l-1 22.9 23.7-46zm-64-20.6l-5.3 28.9 6.6 34.1 1.5-44.9zm30.5 0l-2.7 18 1.2 45 6.7-34.1z"
                                fill="#e4751f" stroke="#e4751f"/>
                            <path
                                d="M179.8 193.5l-6.7 34.1 4.8 3.3 29.2-22.8 1-22.9zm-69.2-8.3l.8 22.9 29.2 22.8 4.8-3.3-6.6-34.1z"
                                fill="#f6851b" stroke="#f6851b"/>
                            <path
                                d="M180.3 262.3l.3-9.3-2.5-2.2h-37.7l-2.3 2.2.2 9.3-31.5-14.9 11 9 22.3 15.5h38.3l22.4-15.5 11-9z"
                                fill="#c0ad9e" stroke="#c0ad9e"/>
                            <path d="M177.9 230.9l-4.8-3.3h-27.7l-4.8 3.3-2.5 22.1 2.3-2.2h37.7l2.5 2.2z" fill="#161616"
                                  stroke="#161616"/>
                            <path
                                d="M278.3 114.2l8.5-40.8-12.7-37.9-96.2 71.4 37 31.3 52.3 15.3 11.6-13.5-5-3.6 8-7.3-6.2-4.8 8-6.1zM31.8 73.4l8.5 40.8-5.4 4 8 6.1-6.1 4.8 8 7.3-5 3.6 11.5 13.5 52.3-15.3 37-31.3-96.2-71.4z"
                                fill="#763d16" stroke="#763d16"/>
                            <path
                                d="M267.2 153.5l-52.3-15.3 15.9 23.9-23.7 46 31.2-.4h46.5zm-163.6-15.3l-52.3 15.3-17.4 54.2h46.4l31.1.4-23.6-46zm71 26.4l3.3-57.7 15.2-41.1h-67.5l15 41.1 3.5 57.7 1.2 18.2.1 44.8h27.7l.2-44.8z"
                                fill="#f6851b" stroke="#f6851b"/>
                        </svg>
                        MetaMask
                    </v-btn>
                </v-col>

                <!-- Wallet Connect -->
                <v-col md="6">
                    <v-btn
                        color="primary"
                        class="text-capitalize wallet-btn"
                        @click="connectWCWallet()"
                        x-large
                        block
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 238" version="1.1" class="mr-4"
                             style="height: 22px;">
                            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="walletconnect-logo-2">
                                    <path
                                        d="M79.4992542,46.5389641 C142.715504,-15.3550143 245.209294,-15.3550143 308.425541,46.5389641 L316.033719,53.9880033 C319.194531,57.0827021 319.194531,62.1002006 316.033719,65.1949006 L290.007661,90.6765804 C288.427255,92.2239311 285.86491,92.2239311 284.284504,90.6765804 L273.814748,80.4258162 C229.713513,37.2470301 158.211283,37.2470301 114.110047,80.4258162 L102.897805,91.4035389 C101.317397,92.9508883 98.755052,92.9508883 97.1746471,91.4035389 L71.1485898,65.9218591 C67.9877774,62.8271591 67.9877774,57.8096606 71.1485898,54.7149619 L79.4992542,46.5389641 Z M362.249885,99.2377722 L385.413126,121.916595 C388.573922,125.01128 388.573941,130.028748 385.413167,133.123453 L280.968512,235.385079 C277.807722,238.479801 272.683033,238.479836 269.522198,235.385159 C269.522185,235.385146 269.52217,235.385132 269.522157,235.385118 L195.393696,162.807065 C194.603493,162.03339 193.32232,162.03339 192.532117,162.807065 C192.532112,162.80707 192.532108,162.807076 192.532103,162.807079 L118.40522,235.385079 C115.24444,238.479809 110.119749,238.479861 106.958905,235.385192 C106.958887,235.385174 106.958867,235.385155 106.958847,235.385136 L2.51128675,133.12215 C-0.649524409,130.027451 -0.649524409,125.009953 2.51128675,121.915254 L25.6745697,99.2364692 C28.8353808,96.1417705 33.9600712,96.1417705 37.1208837,99.2364692 L111.250424,171.815537 C112.040627,172.589212 113.321798,172.589212 114.112002,171.815537 C114.112014,171.815525 114.112024,171.815514 114.112036,171.815505 L188.237861,99.2364692 C191.398601,96.1416993 196.523291,96.1415832 199.684176,99.2362107 C199.68422,99.2362542 199.684263,99.2362977 199.684307,99.2363413 L273.813749,171.815505 C274.603952,172.589179 275.885125,172.589179 276.675327,171.815505 L350.803571,99.2377722 C353.964384,96.1430722 359.089073,96.1430722 362.249885,99.2377722 Z"
                                        id="WalletConnect" fill="#3B99FC" fill-rule="nonzero"></path>
                                </g>
                            </g>
                        </svg>
                        Wallet Connect
                    </v-btn>
                </v-col>
            </v-row>
        </dialog-box>
        <div v-if="isWalletConnected" class="wallet-balance">
            <v-row align="center" justify="space-around" align-content="space-around">
                <div class="hidden-md-and-down">
                    <div class="d-flex align-center justify-end" style="min-width: 250px">
                        <v-scroll-y-transition hide-on-leave>
                            <wallet-balance-web3 v-if="showWeb3Balance"/>
                        </v-scroll-y-transition>
                        <v-scroll-y-reverse-transition hide-on-leave>
                            <wallet-balance-virtual v-if="!showWeb3Balance"/>
                        </v-scroll-y-reverse-transition>
                        <v-btn
                            icon
                            plain
                            small
                            @click="switchBalance"
                        >
                            <v-icon>mdi-cached</v-icon>
                        </v-btn>
                    </div>
                </div>
                <div>
                    <v-btn
                        :to="{ name: 'BuyToken', params: {}}"
                        class="mx-2 darken hidden-md-and-down"
                        color="golden"
                        outlined
                    >
                        <div class="bg-menu_icon_buy_scrl mr-2"></div>
                        Get SCRL
                    </v-btn>
                    <v-menu :open-on-hover="isXLarge" light offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                :large="isXLarge"
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-row no-gutters>
                            <span class="address-short mr-5 d-flex align-center">
                                <v-icon>mdi-wallet-outline</v-icon>&nbsp;{{ web3.address | shortAddress }}
                            </span>
                                    <v-icon>mdi-chevron-down</v-icon>
                                </v-row>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="disconnectWallet()">
                                <v-list-item-title>
                                    <v-icon>mdi-exit-to-app</v-icon>
                                    Disconnect
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </v-row>

        </div>
    </div>
</template>

<script>
import WalletIntegration from '../wallet-integration';
import DialogBox from "./dialog-box";
import {addTokenToProvider} from "../services/contracts/tokenContract";
import MainButton from "@/components/ui/main-button";
import WalletBalanceWeb3 from "@/components/wallet-balance-web3";
import WalletBalanceVirtual from "@/components/wallet-balance-virtual";

export default {
    components: {WalletBalanceVirtual, WalletBalanceWeb3, MainButton, DialogBox},
    props: ['isXLarge'],
    data() {
        return {
            walletChooseDialogOpen: false,
            showWeb3Balance: true,
        }
    },
    mounted() {
    },
    computed: {
        web3() {
            return this.$store.state.web3;
        },
        isWalletConnected() {
            return this.web3.isWalletConnected;
        }
    },
    methods: {
        switchBalance() {
            this.showWeb3Balance = !this.showWeb3Balance;
        },
        connectMetamaskWallet() {
            WalletIntegration.connectMetamask();
            this.$gtm.trackEvent({
                'event': 'GA-Connect-Wallet-Metamask',
            });
        },
        connectWCWallet() {
            WalletIntegration.connectWalletConnect();
            this.walletChooseDialogOpen = false;
            this.$gtm.trackEvent({
                'event': 'GA-Connect-Wallet-Wallet-connect',
            });
        },
        addTokenToProvider() {
            addTokenToProvider();
        },
        disconnectWallet() {
            WalletIntegration.disconnect();
            this.$gtm.trackEvent({
                'event': 'GA-Disconnect-Wallet',
            });
        }
    }
};
</script>

<style scoped>
.wallet-balance span,
.wallet-balance button span {
    color: var(--v-golden-base);
    display: flex;
    flex-wrap: wrap;
}

.wallet-balance button span.address-short {
    color: #fff;
    text-transform: none;
}

.wallet-balance img {
    width: 20px;
    cursor: pointer;
    margin-right: 5px;
}

.wallet-balance a {
    cursor: pointer;
    color: var(--v-golden-base) !important;
    margin: 0 0 0 6px;
}


.bg-menu_icon_buy_scrl {
    width: 15px;
    height: 18px;
    background: url('/img/icons/common-icons.png') -45px -95px;
}


.bg-menu_icon_buy_scrl {
    width: 15px;
    height: 18px;
    background: url('/img/icons/common-icons.png') -45px -95px;
}

@media only screen and (max-width: 350px) {
    .v-btn {
        padding: 5px !important;
    }

    .wallet-balance button span {
        font-size: 80%;
    }
}
</style>
