var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app-bar",
    {
      staticClass: "navbar-container",
      attrs: { app: "", height: _vm.displayAnnouncement ? 94 : null },
    },
    [
      _vm.displayAnnouncement
        ? _c(
            "div",
            {
              staticClass:
                "announcement-panel d-flex align-center justify-center",
            },
            [
              _c("b", { staticClass: "mr-1" }, [_vm._v("Open Beta")]),
              _vm._v(" is available to play! "),
              _c(
                "v-btn",
                {
                  staticClass: "ml-2",
                  attrs: {
                    "x-small": "",
                    color: "warning",
                    href: "https://drive.google.com/drive/folders/1T306i-_Zw2WjKA9n7Gvmos2YvrEYHQ1K",
                    target: "_blank",
                  },
                },
                [
                  _c("v-icon", [_vm._v("mdi-download")]),
                  _vm._v(" Download here "),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-toolbar",
        {
          staticClass: "navbar",
          class: { "announcement-margin": _vm.displayAnnouncement },
          attrs: { color: "navbarBg", fixed: "", elevation: "0" },
        },
        [
          _c(
            "router-link",
            { staticClass: "navbar-brand mr-8", attrs: { to: "/" } },
            [
              _c("img", {
                staticClass: "pt-2 hidden-sm-and-down",
                staticStyle: { width: "130px" },
                attrs: { src: "/img/logo-mini.png", alt: "Wizarre logo" },
              }),
              _c("img", {
                staticClass: "pt-2 hidden-md-and-up",
                staticStyle: { width: "45px" },
                attrs: { src: "/img/logo-W-mini.png", alt: "Wizarre logo" },
              }),
            ]
          ),
          _vm._l(_vm.menuContent, function (menuPos) {
            return _c(
              "div",
              { key: "te-" + menuPos.text },
              [
                menuPos.links && menuPos.links.length > 0
                  ? _c(
                      "v-menu",
                      {
                        attrs: {
                          dark: "",
                          "offset-y": "",
                          "open-on-hover": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "button",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass:
                                            "nav-item text-capitalize hidden-sm-and-down",
                                        },
                                        "button",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _vm._v(" " + _vm._s(menuPos.text) + " "),
                                      _c("v-icon", [
                                        _vm._v("mdi-chevron-down"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _c(
                          "v-list",
                          { staticClass: "hidden-sm-and-down" },
                          _vm._l(menuPos.links, function (link) {
                            return _c(
                              "v-list-item",
                              {
                                key: "l-" + link.routeName,
                                class: link.class,
                                attrs: {
                                  to: { name: link.routeName, params: {} },
                                  "active-class": "golden darken-1",
                                },
                              },
                              [
                                _c(
                                  "v-list-item-title",
                                  {
                                    staticClass: "d-flex flex-row align-center",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "nav-item-icon" },
                                      [
                                        link.mdiIcon
                                          ? _c(
                                              "v-icon",
                                              { attrs: { small: "" } },
                                              [_vm._v(_vm._s(link.mdiIcon))]
                                            )
                                          : _vm._e(),
                                        link.bgIcon
                                          ? _c("div", { class: link.bgIcon })
                                          : _vm._e(),
                                        link.imgIcon
                                          ? _c("div", [
                                              _c("img", {
                                                attrs: {
                                                  src: link.imgIcon,
                                                  alt: "",
                                                },
                                              }),
                                            ])
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "nav-item-text" },
                                      [_vm._v(" " + _vm._s(link.text) + " ")]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      [
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "text-decoration-none hidden-sm-and-down",
                            attrs: {
                              to: { name: menuPos.routeName, params: {} },
                            },
                          },
                          [
                            _c(
                              "button",
                              { staticClass: "nav-item text-capitalize" },
                              [_vm._v(" " + _vm._s(menuPos.text) + " ")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
              ],
              1
            )
          }),
          _c("v-spacer"),
          _c("wallet-button"),
          _vm.isWalletConnected
            ? _c(
                "v-btn",
                {
                  staticClass: "ml-5",
                  attrs: {
                    to: { name: "Profile", params: {} },
                    disabled: !_vm.userAccount.username,
                    loading: !_vm.userAccount.username,
                  },
                },
                [
                  _c("v-img", {
                    staticClass: "avatar-img",
                    attrs: { src: _vm.avatarSrc },
                  }),
                  _c("span", { staticClass: "d-none d-md-block" }, [
                    _vm._v(" Profile "),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c("v-spacer", { staticClass: "hidden-md-and-up" }),
          _c("v-app-bar-nav-icon", {
            staticClass: "d-flex d-lg-none hidden-sm-and-up",
            staticStyle: { background: "none" },
            on: {
              click: function ($event) {
                _vm.drawer = !_vm.drawer
              },
            },
          }),
        ],
        2
      ),
      _c(
        "v-navigation-drawer",
        {
          attrs: {
            width: "80%",
            absolute: "",
            temporary: "",
            height: "100vh",
            color: "background",
          },
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _c(
            "v-list",
            { attrs: { nav: "", dense: "", color: "navbarBg" } },
            [
              _c(
                "v-list-item-group",
                [
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "navbar-brand mr-4",
                          attrs: { to: "/" },
                        },
                        [
                          _c("img", {
                            staticClass: "pt-2",
                            staticStyle: { width: "130px" },
                            attrs: {
                              alt: "Wizarre logo",
                              src: "/img/logo-mini.png",
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-start flex-wrap-reverse" },
                    [
                      _c(
                        "div",
                        [
                          _c("wallet-balance-web3"),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mb-2 ml-4",
                              attrs: {
                                to: { name: "BuyToken", params: {} },
                                color: "golden",
                                outlined: "",
                                small: "",
                              },
                            },
                            [
                              _c("div", {
                                staticClass: "bg-menu_icon_buy_scrl mr-2",
                              }),
                              _vm._v(" Get SCRL "),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("wallet-balance-virtual"),
                      _c("v-spacer"),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _vm._l(_vm.menuContent, function (menuPos) {
                    return _c(
                      "div",
                      { key: "tm-" + menuPos.text },
                      [
                        menuPos.links && menuPos.links.length > 0
                          ? _c(
                              "v-subheader",
                              { staticClass: "white--text font-weight-bold" },
                              [_vm._v(" ↓ " + _vm._s(menuPos.text) + " ")]
                            )
                          : _c(
                              "v-subheader",
                              { staticClass: "white--text font-weight-bold" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "text-decoration-none",
                                    attrs: {
                                      to: {
                                        name: menuPos.routeName,
                                        params: {},
                                      },
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(menuPos.text) + " ")]
                                ),
                              ],
                              1
                            ),
                        _vm._l(menuPos.links, function (link) {
                          return _c(
                            "v-list-item",
                            {
                              key: "l-" + link.routeName,
                              class: link.class,
                              attrs: {
                                to: { name: link.routeName, params: {} },
                              },
                            },
                            [
                              _c(
                                "v-list-item-title",
                                { staticClass: "d-flex flex-row align-center" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "nav-item-icon" },
                                    [
                                      link.mdiIcon
                                        ? _c(
                                            "v-icon",
                                            { attrs: { dense: "" } },
                                            [_vm._v(_vm._s(link.mdiIcon))]
                                          )
                                        : _vm._e(),
                                      link.bgIcon
                                        ? _c("div", { class: link.bgIcon })
                                        : _vm._e(),
                                      link.imgIcon
                                        ? _c("div", [
                                            _c("img", {
                                              attrs: {
                                                src: link.imgIcon,
                                                alt: "",
                                              },
                                            }),
                                          ])
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "nav-item-text" }, [
                                    _vm._v(" " + _vm._s(link.text) + " "),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        }),
                      ],
                      2
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }