<template>
    <div class="balance-indicator mt-1">
        <img height="26px" src="/img/tokens/v-scroll_48.png" width="26px"/>
        <span id="balance" class="pr-3 pl-1">{{ formattedBalance }}</span>
        <span>vSCRL</span>
    </div>
</template>

<script>

export default {
    data: () => ({}),
    props: [''],
    computed: {
        virtualWallet() {
            return this.$store.state.virtualWallet;
        },
        formattedBalance() {
            const balance = this.virtualWallet.balance;
            return this.$options.filters.localFormatNumber(balance);
        },
    },
    methods: {}
};
</script>

<style lang="scss" scoped>
.balance-indicator {
    padding: 6px 6px;
    line-height: 26px;
    display: flex;
    justify-items: center;
}

.balance-indicator span,
.balance-indicator button span {
    font-family: 'STIX Two Text', serif;
    color: var(--v-golden-darken2);
    display: flex;
    font-size: 24px;
}

.balance-indicator button span {
    color: #fff;
    text-transform: none;
}

.balance-indicator a {
    cursor: pointer;
    color: var(--v-golden-darken1) !important;
}

#balance {
    justify-content: right;
}


@media only screen and (max-width: $md) {
    .balance-indicator span {
        font-size: 16px;
    }
}

@media only screen and (max-width: $xs) {
    .balance-indicator span {
        font-size: 12px;
    }
}

</style>
