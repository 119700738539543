<template>
    <div>
        <dialog-box
            :model="unsupportedChainDialog"
            persistent
            width="800"
            title="Unsupported network"
        >
            <br/>
            Your wallet is currently using an unsupported network.<br/>
            Change to <b>Binance Smart Chain ({{ chainName }})</b> network

            <div class="mt-10 text-center">
                <v-btn class="mr-10" @click="switchNetwork()">
                    Switch to BSC Network
                </v-btn>
                <v-btn class="mr-10" @click="addNetwork()">
                    Add BSC Network
                </v-btn>
                <v-btn color="" @click="disconnect()">
                    Disconnect
                </v-btn>
            </div>
        </dialog-box>

        <dialog-box
            :model="noWeb3Wallet"
            persistent
            width="700"
            title="No Web3 wallet installed"
        >
            <br/>
            To continue, please install a Web3 wallet browser plugin. We recommend using MetaMask

            <div class="mt-10 text-center">
                <v-btn class="mr-10" href="https://metamask.io/download/" target="_blank">
                    Install MetaMask
                </v-btn>
                <v-btn class="mr-10" @click="noWeb3Wallet=false">
                    Exit
                </v-btn>
            </div>
        </dialog-box>

        <dialog-box
            :model="disconnectedDialog"
            persistent
            width="800"
            title="Disconnected wallet"
        >
            <br/>
            Your wallet has been disconnected

            <div class="mt-10 text-center">
                <v-btn class="mr-10" @click="disconnectedOkClick()">
                    Ok
                </v-btn>
            </div>
        </dialog-box>
    </div>
</template>

<script>
import FooterBar from "../footer-bar";
import WalletIntegration from "../../wallet-integration";
import DialogBox from "../dialog-box";
import {UnauthorizedChainError, WalletError, WalletErrorHandler} from "@/errors/WalletErrors";

export default {
    components: {DialogBox, FooterBar},
    props: {},
    data() {
        return {
            unsupportedChainDialog: false,
            noWeb3Wallet: false,
            disconnectedDialog: false,
        }
    },
    mounted() {
        window.addEventListener('event-wrong-network', () => {
            this.unsupportedChainDialog = true;
        });
        window.addEventListener('event-no-web3-wallet', () => {
            this.noWeb3Wallet = true;
        });
        window.addEventListener('event-disconnect', () => {
            this.disconnectedDialog = true;
            WalletIntegration.setConnectedWallet(null);
        });
    },
    computed: {
        chainName() {
            return process.env.VUE_APP_BLOCKCHAIN_CHAIN_NAME;
        },
    },
    methods: {
        async switchNetwork() {
            WalletIntegration.switchUserNetwork()
                .then(() => {
                    this.unsupportedChainDialog = false;
                    WalletIntegration.connectMetamask();
                })
                .catch((error) => {
                    try {
                        WalletErrorHandler.handleTxError(error);
                    } catch (error2) {
                        if (error2 instanceof UnauthorizedChainError) {
                            this.addNetwork();
                        } else if (error2 instanceof WalletError) {
                            // do nothing
                        } else {
                            console.error(error);
                        }
                    }
                })
        },
        addNetwork() {
            WalletIntegration.addUserNetwork()
                .then(() => {
                    this.switchUserNetwork();
                    this.unsupportedChainDialog = false;
                })
                .catch((e) => {
                    // do nothing
                })
        },
        disconnectedOkClick() {
            window.location.reload();
        },
        disconnect() {
            WalletIntegration.disconnect(false);
            this.unsupportedChainDialog = false;
            this.disconnectedDialog = false;
        },
    }
}
</script>

<style lang="scss" scoped>

</style>
