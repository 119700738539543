import {apiServiceVue} from "@/services/apiService.vue";

export default class {
    #url = process.env.VUE_APP_VIRTUAL_WALLET_URL;

    async getStatus() {
        const response = await apiServiceVue.get(
            this.#url
            + '/status'
        )
        return response.data;
    }

    async getVScrlBalance(walletAddress) {
        const response = await apiServiceVue.get(
            this.#url
            + walletAddress
            + '/balance'
        )
        return response.data.balance;
    }

    async getTransactionsHistory(walletAddress) {
        const response = await apiServiceVue.get(
            this.#url
            + walletAddress
            + '/transactions'
        )
        return response.data.history;
    }

    async getWithdrawsFee() {
        const response = await apiServiceVue.get(
            this.#url
            + '/withdraws'
            + '/fee'
        )
        return response.data.fee;
    }

    async getWithdrawsInProgress(walletAddress) {
        const response = await apiServiceVue.get(
            this.#url
            + walletAddress
            + '/withdraws'
            + '/in-progress'
        )
        return response.data.withdraws;
    }

    async getLatestWithdraw(walletAddress) {
        const response = await apiServiceVue.get(
            this.#url
            + walletAddress
            + '/withdraws'
            + '/latest'
        )
        return response.data.withdraw;
    }

    async getWithdrawsNonce(walletAddress) {
        const response = await apiServiceVue.get(
            this.#url
            + walletAddress
            + '/withdraws'
            + '/nonce'
        )
        return response.data.nonce;
    }

    async openNewWithdraw(
        walletAddress,
        hashedNonce,
        amountAlreadyWithdrawn,
        amountOfTotalWithdrawAllowance
    ) {
        return await apiServiceVue.post(
            this.#url
            + walletAddress
            + '/withdraws'
            + '/new',
            {
                hashedNonce: hashedNonce,
                amountAlreadyWithdrawn: amountAlreadyWithdrawn,
                amountOfTotalWithdrawAllowance: amountOfTotalWithdrawAllowance,
            }
        );
    }
};
