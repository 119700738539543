<template>
    <v-footer app absolute color=rgba(56,41,74,0.5) elevation=15
              class="d-flex flex-column align-stretch px-8 py-2">
        <div class="mb-1 d-flex flex-wrap justify-space-between align-center">
            <router-link to="/" class="links-container">
                <img alt="Wizarre logo" height="55px" src="/img/logo-mini.png"/>
            </router-link>
            <div class="social-links">
                <v-btn v-for="(socialLink, i) in socialLinks" :key="`social_${i}`"
                       fab small class="ml-0 ml-sm-2 mr-2 mr-sm-0" :href="socialLink.link"
                       :title="socialLink.title" target="_blank"
                >
                    <v-icon v-if="socialLink.icon" class="mdi-24px">{{ socialLink.icon }}</v-icon>
                    <img v-if="socialLink.img" width="25px" :src="socialLink.img" :alt="socialLink.title"/>
                </v-btn>
            </div>
        </div>
        <div class="d-flex flex-wrap align-md-start justify-space-between justify-md-start mr-2 mr-sm-0">
            <div class="d-flex flex-column align-baseline align-self-end links-container">
                <a v-for="(tradeLink, i) in tradeLinks" :key="`trade_${i}`"
                   class="text--secondary text-none d-flex align-center" :href="tradeLink.link"
                   :title="tradeLink.title" target="_blank"
                >
                    <img height="15px" :src="tradeLink.img" :alt="tradeLink.title" class="mr-1"/>
                    {{ tradeLink.text }}
                </a>
            </div>
            <div class="d-flex flex-column align-baseline links-container">
                <a v-for="(tokenLink, i) in tokenLinks" :key="`token_${i}`"
                   class="text--secondary text-none d-flex align-center" :href="tokenLink.link"
                   :title="tokenLink.title" target="_blank"
                >
                    <img height="15px" :src="tokenLink.img" :alt="tokenLink.title" class="mr-1"/>
                    {{ tokenLink.text }}
                </a>
            </div>
            <div class="ml-md-auto align-self-end d-flex flex-column align-md-end">
                <div>
                    <a class="text--primary" href="https://www.wizarre.io/privacy-policy" target="_blank">Privacy Policy <v-icon x-small>mdi-open-in-new</v-icon></a>
                    |
                    <a class="text--primary" href="https://www.wizarre.io/eula" target="_blank">EULA <v-icon x-small>mdi-open-in-new</v-icon></a>
                </div>
                <div class="text--secondary">
                    © 2024 Wizarre. All rights reserved.
                </div>
            </div>
        </div>
        <div class="download-section">
            <div class="d-flex flex-row justify-center">
                <div>
                    <a href="https://play.google.com/store/apps/details?id=com.FlyingCactus.Wizarre&referrer=utm_source%3Dapp%26utm_medium%3Dfooter"
                       target="_blank">
                        <img src="/img/download-game/google-play-badge.png">
                    </a>
                </div>
                <div>
                    <a href="https://cdn.wizarre.io/wizarre-1.6.0.zip" target="_blank">
                        <img src="/img/download-game/download-windows.png">
                    </a>
                </div>
<!--                <div class="ios mr-5">-->
<!--                    <a>-->
<!--                        <img class="disabled" src="/img/download-game/ios.svg">-->
<!--                    </a>-->
<!--                    <span class="text-center d-block">Available soon</span>-->
<!--                </div>-->
            </div>
        </div>
    </v-footer>
</template>

<script>

export default {
    props: {},
    data() {
        return {
            socialLinks: [
                {link: "https://wizarre.io/", img: "/img/logo-W-mini.png", title: "Wizarre Official Site"},
                // {link: "https://discord.gg/wizarre-community-910482670918709279", icon: "mdi-discord", title: "Discord"},
                {link: "https://t.me/WizarreAnnounce", img: "/img/icons/social/telegram.svg", title: "Telegram"},
                {link: "https://twitter.com/WizarreGame/", img: "/img/icons/social/x-logo-margin_60.png", title: "X / Twitter"},
                // {link: "https://www.facebook.com/WizarreGame", icon: "mdi-facebook", title: "Facebook"},
                {link: "https://www.youtube.com/c/WizarreGame", icon: "mdi-youtube", title: "YouTube"},
                {link: "https://medium.com/@wizarre", img: "/img/icons/social/medium.svg", title: "Medium"},
            ],
            tokenLinks: [
                {
                    text: 'SCRL on CoinMarketCap',
                    img: '/img/icons/crypto/cmc.ico',
                    link: 'https://coinmarketcap.com/currencies/wizarre/',
                    title: "CoinMarketCap"
                },
                {
                    text: 'SCRL on CoinGecko',
                    img: '/img/icons/crypto/coingecko.ico',
                    link: 'https://www.coingecko.com/en/coins/wizarre-scroll',
                    title: "CoinGecko"
                },
            ],
            tradeLinks: [
                {
                    text: 'Trade on PancakeSwap',
                    img: '/img/icons/crypto/pancake.ico',
                    link: 'https://pancakeswap.finance/swap?inputCurrency=0x55d398326f99059fF775485246999027B3197955&outputCurrency=0x52c1751C89fc913ed274d72e8d56DcE4Ee44A5cf',
                    title: "PancakeSwap"
                },
                {
                    text: 'Trade with Bank Card',
                    img: '/img/icons/crypto/swipelux-logo.svg',
                    link: 'https://app.wizarre.io/buy-token',
                    title: "Swipelux"
                },
            ]
        }
    },
    mounted() {
    },
    computed: {},
    methods: {}
};
</script>

<style lang="scss" scoped>
.download-section {
    width: 500px;
    position: absolute;
    text-align: center;
    top: 35px;
    left: 0;
    right: 0;
    margin: auto;


    .ios span {
        line-height: 5px;
    }

    a {
        margin-right: 10px;

        img {
            height: 50px;

            &.disabled {
                opacity: 0.5;
            }
        }
    }
}

.links-container {
    min-width: 200px;
}

.social-links .v-btn > .v-btn__content .v-icon {
    color: #c7c0d2;
}

@media only screen and (max-width: 1350px) {
    .download-section {
        top: 20px;
        width: 400px;

        a img {
            height: 40px;
        }
    }
}

@media only screen and (max-width: 1050px) {
    .download-section {
        display: none;
    }
}
</style>
