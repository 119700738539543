<template>
    <div class="main-button main-button-shadow mr-2" :class="{'big':big, 'glow':glow, 'disabledBtn':disabled}">
        <v-btn
            :ripple="false"
            class="main-button button d-flex align-center justify-center"
            :class="{
                'big':big,
            }"
            :disabled="disabled"
            :loading="loading"
            :small=small
            @click="onClick()"
        >
            <slot></slot>
        </v-btn>
    </div>
</template>

<script>
export default {
    props: {
        disabled: Boolean,
        loading: Boolean,
        onClick: {
            type: Function,
            default: () => {
            }
        },
        big: Boolean,
        small: Boolean,
        glow: Boolean,
    },
    data() {
        return {}
    },
    created() {
    },
    computed: {},
    methods: {}
};
</script>

<style lang="scss">
.main-button-shadow {
    padding-bottom: 3px;
    border-radius: 12px;
    box-shadow: 0 3px 0 rgba(0, 0, 0, .2);

    &.big {
        padding-bottom: 4px;
        box-shadow: 0 4px 0 rgba(0, 0, 0, .2);
    }

    &:not(.disabledBtn).glow {
        animation: glow 2s alternate infinite !important;
    }
}

button.main-button {
    text-transform: none !important;
    background: #d48702 !important;
    border-radius: 12px;
    border: 3px solid #ffc602 !important;
    text-shadow: rgba(0, 0, 0, .3) 0 2px 0;
    box-shadow: 0 4px 0 #be7613;
    padding: 0 8px !important;

    &:hover {
        background: #eaac1e !important;
        border-color: #ffe852 !important;
    }

    &.v-btn--disabled {
        span.v-btn__content {
            color: #9eb4b3 !important;
        }

        background: #4e6d79 !important;
        border-color: #829899 !important;
        box-shadow: 0 4px 0 #384e5c;
    }

    &.big {
        padding: 20px 40px !important;
        font-size: 180%;
        font-weight: bold;
        border-width: 4px !important;
    }
}

@keyframes glow {
    from {
        box-shadow: 0 0 7px -7px #aef4af;
    }
    to {
        box-shadow: 0 0 7px 7px #aef4af;
    }
}

.main-button {
    width: 100%;
}
</style>
