import Vue from 'vue';
import VueRouter from 'vue-router';
import PermissionHelper from "@/helpers/permissionHelper";

Vue.use(VueRouter);

const addTitle = (to) => {
    let title = to.meta.title ? to.meta.title : '';

    title = title.replace('###', '#' + to.params.id);

    if (title) {
        title += ' | Wizarre';
    } else {
        title = 'Wizarre';
    }
    document.title = title;
}

const addDescriptionTag = (to) => {
    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
    if (nearestWithMeta) {
        // Turn the meta tag definitions into actual elements in the head.
        nearestWithMeta.meta.metaTags.map(tagDef => {
            const tag = document.createElement('meta');

            Object.keys(tagDef).forEach(key => {
                let value = tagDef[key].replace('###', '#' + to.params.id);
                tag.setAttribute(key, value);
            });

            // We use this to track which meta tags we create so we don't interfere with other ones.
            tag.setAttribute('data-vue-router-controlled', '');

            return tag;
        })
            // Add the meta tags to the document head.
            .forEach(tag => document.head.appendChild(tag));
    }
}

const allowOnlyAdmins = (to, from, next) => {
    return PermissionHelper.allowOnlyFor(to, from, next, 'AdminDashboard');
}

const allowOnlySage = (to, from, next) => {
    return PermissionHelper.allowOnlyFor(to, from, next, 'ClaimTokenSage');
}

const allowOnlyTeam = (to, from, next) => {
    return PermissionHelper.allowOnlyFor(to, from, next, 'ClaimTokenTeam');
}

const routes = [
    {
        path: '/',
        name: 'Home',
        meta: {
            title: 'Home',
            metaTags: [
                {
                    name: 'description',
                    content: 'Immerse yourself in this magical blockchain world and discover what the Wizarre APP hides!'
                }
            ]
        },
        component: () => import('../views/Home.vue')
    },
    {
        path: '/summoning',
        name: 'Summoning',
        meta: {
            title: 'Summoning',
            metaTags: [
                {
                    name: 'description',
                    content: 'Ritual of Summoning - breed your NFT Wizards'
                }
            ]
        },
        component: () => import('../views/Summoning'),
    },
    {
        path: '/my-wizards',
        name: 'MyWizards',
        meta: {
            title: 'My Wizards',
            metaTags: [
                {
                    name: 'description',
                    content: 'Check the collection of your NFT Wizards'
                }
            ]
        },
        component: () => import('../views/MyWizards.vue')
    },
    {
        path: '/wizard/:id',
        name: 'Wizard',
        meta: {
            title: 'Wizard ###',
            metaTags: [
                {
                    name: 'description',
                    content: 'Comprehensive overview of the NFT Wizard ###'
                }
            ]
        },
        component: () => import('../views/Wizard.vue')
    },
    {
        path: '/my-lands',
        name: 'MyLands',
        meta: {
            title: 'My Lands',
            metaTags: [
                {
                    name: 'description',
                    content: 'Check the collection of your NFT Lands'
                }
            ]
        },
        component: () => import('../views/MyLands.vue')
    },
    {
        path: '/land/:id',
        name: 'Land',
        meta: {
            title: 'Land ###',
            metaTags: [
                {
                    name: 'description',
                    content: 'Comprehensive overview of the NFT Land ###'
                }
            ]
        },
        component: () => import('../views/Land.vue')
    },
    {
        path: '/my-cards',
        name: 'MyCards',
        meta: {
            title: 'My Cards',
            metaTags: [
                {
                    name: 'description',
                    content: 'Check the collection of your collectible NFT Cards'
                }
            ]
        },
        component: () => import('../views/MyCards.vue')
    },
    {
        path: '/bottomless-bag',
        name: 'MyEnhancements',
        meta: {
            title: 'Bottomless bag',
            metaTags: [
                {
                    name: 'description',
                    content: 'Check the collection of your NFT Enhancements'
                }
            ]
        },
        component: () => import('../views/MyEnhancements.vue')
    },
    {
        path: '/enhancement/:id',
        name: 'Enhancement',
        meta: {
            title: 'Enhancement ###',
            metaTags: [
                {
                    name: 'description',
                    content: 'Comprehensive overview of the magical NFT Enhancement'
                }
            ]
        },
        component: () => import('../views/Enhancement.vue')
    },
    {
        path: '/wizard-staking',
        name: 'WizardStaking',
        meta: {
            title: 'Wizard Staking',
            metaTags: [
                {
                    name: 'description',
                    content: 'Unstake your SCRL tokens to claim your free Genesis NFT Wizard'
                }
            ]
        },
        component: () => import('../views/Staking.vue')
    },
    {
        path: '/claim-token',
        name: 'ClaimToken',
        meta: {
            title: 'Claim SCRL Token',
        },
        component: () => import('../views/ClaimToken.vue'),
    },
    {
        path: '/claim-token-sage',
        name: 'ClaimTokenSage',
        meta: {
            title: 'Claim Sage pool',
        },
        component: () => import('../views/ClaimTokenSage.vue'),
        beforeEnter: allowOnlySage,
    },
    {
        path: '/claim-token-team',
        name: 'ClaimTokenTeam',
        meta: {
            title: 'Claim Team pool'
        },
        component: () => import('../views/ClaimTokenTeam.vue'),
        beforeEnter: allowOnlyTeam,
    },
    {
        path: '/land-lottery',
        name: 'LandLottery',
        meta: {
            title: 'Land Lottery',
            metaTags: [
                {
                    name: 'description',
                    content: 'Participate in the Land Lottery event to win NFT Lands'
                }
            ]
        },
        component: () => import('../views/LandLottery.vue')
    },
    {
        path: '/contract-info',
        name: 'ContractInfo',
        meta: {
            title: 'Contract Info',
            metaTags: [
                {
                    name: 'description',
                    content: 'The list of contracts we are using for Wizarre'
                }
            ]
        },
        component: () => import('../views/ContractInfo.vue')
    },
    {
        path: '/map',
        name: 'Map',
        meta: {
            title: 'World Map',
            metaTags: [
                {
                    name: 'description',
                    content: 'Check the detailed World Map of the blockchain NFT game - Wizarre'
                }
            ]
        },
        component: () => import('../views/WorldMap.vue')
    },
    {
        path: '/map-dev',
        name: 'MapDev',
        meta: {
            title: 'Map DEV'
        },
        component: () => import('../views/MapDev.vue')
    },
    {
        path: '/staking-map',
        name: 'StakingMap',
        meta: {
            title: 'Staking Map',
            metaTags: [
                {
                    name: 'description',
                    content: 'Stake SCRL token to receive various rewards'
                }
            ]
        },
        component: () => import('../views/StakingMap.vue')
    },
    {
        path: '/admin-info',
        name: 'Admin',
        meta: {
            title: 'ADMIN'
        },
        component: () => import('../views/Admin.vue'),
        beforeEnter: allowOnlyAdmins,
    },
    {
        path: '/classified/dashboard',
        name: 'AdminDashboard',
        meta: {
            title: 'ADMIN_DASHBOARD'
        },
        component: () => import('../views/AdminDashboard.vue'),
        beforeEnter: allowOnlyAdmins,
    },
    {
        path: '/classified/contests',
        name: 'AdminContests',
        meta: {
            title: 'ADMIN_CONTESTS'
        },
        component: () => import('../views/AdminContests'),
        beforeEnter: allowOnlyAdmins,
    },
    {
        path: '/alpha/ranking',
        name: 'AlphaRanking',
        meta: {
            title: 'Alpha Ranking'
        },
        redirect: to => {
            return 'profile'
        },
    },
    {
        path: '/alpha',
        name: 'Alpha',
        meta: {
            title: 'Alpha'
        },
        redirect: to => {
            return 'profile'
        },
    },
    {
        path: '/marketplace',
        name: 'Marketplace',
        meta: {
            title: 'NFT Marketplace',
            metaTags: [
                {
                    name: 'description',
                    content: 'Trade your NFT Wizards, NFT Lands, and more with other players'
                }
            ]
        },
        component: () => import('../views/Market.vue')
    },
    {
        path: '/profile',
        name: 'Profile',
        meta: {
            title: 'Profile',
            metaTags: [
                {
                    name: 'description',
                    content: 'Manage your user account and check out the latest challenges to win and claim NFT rewards'
                }
            ]
        },
        component: () => import('../views/Profile.vue')
    },
    {
        path: '/buy-token',
        name: 'BuyToken',
        meta: {
            title: 'Get Token',
            metaTags: [
                {
                    name: 'description',
                    content: 'Buy and manage your SCRL tokens'
                }
            ]
        },
        component: () => import('../views/BuyToken.vue')
    },
    {
        path: '/redeem-code',
        name: 'RedeemCode',
        meta: {
            title: 'Redeem Promo Code',
            metaTags: [
                {
                    name: 'description',
                    content: 'Here you can redeem your promo code for valuable rewards'
                }
            ]
        },
        component: () => import('../views/RedeemCode.vue')
    },
    {
        path: '/leaderboard',
        name: 'Leaderboard',
        meta: {
            title: 'Leaderboard - preseason',
            metaTags: [
                {
                    name: 'description',
                    content: 'Preseason leaderboard, check top 100 best players'
                }
            ]
        },
        component: () => import('../views/Leaderboard.vue')
    },
    {
        path: '/wizarropedia/enhancements',
        name: 'WizarropediaEnhancements',
        meta: {
            title: 'Wizarropedia - Enhancements',
            metaTags: [
                {
                    name: 'description',
                    content: 'Available enhancements list'
                }
            ]
        },
        component: () => import('../views/Wizarropedia/WizarropediaEnhancements.vue')
    },
    {
        path: '/wizarropedia/spells',
        name: 'WizarropediaSpells',
        meta: {
            title: 'Wizarropedia - Spells',
            metaTags: [
                {
                    name: 'description',
                    content: 'Wizards spells list'
                }
            ]
        },
        component: () => import('../views/Wizarropedia/WizarropediaSpells.vue')
    },
    {
        path: '/wizarropedia/lands',
        name: 'WizarropediaLands',
        meta: {
            title: 'Wizarropedia - Lands',
            metaTags: [
                {
                    name: 'description',
                    content: 'Lands list and powers'
                }
            ]
        },
        component: () => import('../views/Wizarropedia/WizarropediaLands.vue')
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    addTitle(to);
    addDescriptionTag(to);
    next();
});

export default router
