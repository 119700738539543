var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "select-dialog" },
    [
      _c(
        "v-dialog",
        {
          attrs: {
            width: _vm.width,
            "max-width": "96%",
            scrollable: "",
            "overlay-opacity": "0.8",
            persistent: _vm.persistent,
            fullscreen: _vm.fullscreen,
          },
          model: {
            value: _vm.isOpen,
            callback: function ($$v) {
              _vm.isOpen = $$v
            },
            expression: "isOpen",
          },
        },
        [
          _c(
            "v-card",
            {
              staticClass:
                "dialog-box-decorated-edges-mini dialog-container py-2 py-sm-4 px-4 px-sm-8",
            },
            [
              _c("div", {
                staticClass: "dialog-box-decorated-edges-close-btn-mini",
                on: {
                  click: function ($event) {
                    _vm.isOpen = true
                  },
                },
              }),
              _vm.title
                ? _c("v-card-title", { staticClass: "dialog-header mb-0" }, [
                    _c(
                      "h3",
                      {
                        staticClass: "text-center w-100",
                        class: {
                          highlight: _vm.highlightTitle,
                          basic: !_vm.highlightTitle,
                        },
                      },
                      [_vm._v(_vm._s(_vm.title))]
                    ),
                  ])
                : _vm._e(),
              _vm.removeVCardWrap
                ? _vm._t("default")
                : _c("v-card-text", [_vm._t("default")], 2),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }