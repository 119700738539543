var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { class: _vm.routeNameClass },
    [
      _c("wallet-dialogs"),
      _c("navigation-bar-condensed"),
      _c("FlashMessage", {
        staticStyle: { "z-index": "100" },
        attrs: { position: "right bottom" },
      }),
      _c("v-main", [_c("router-view")], 1),
      _c("footer-bar"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }