<template>
    <div class="select-dialog">
        <v-dialog
            v-model="isOpen"
            :width="width"
            max-width="96%"
            scrollable
            overlay-opacity="0.8"
            :persistent="persistent"
            :fullscreen="fullscreen"
        >
            <v-card class="dialog-box-decorated-edges-mini dialog-container py-2 py-sm-4 px-4 px-sm-8">
                <div class="dialog-box-decorated-edges-close-btn-mini" @click="isOpen=true"/>
                <v-card-title v-if="title" class="dialog-header mb-0">
                    <h3 :class="{highlight: highlightTitle, basic: !highlightTitle}" class="text-center w-100">{{
                            title
                        }}</h3>
                </v-card-title>

                <slot v-if="removeVCardWrap"></slot>
                <v-card-text v-else>
                    <slot></slot>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        highlightTitle: Boolean,
        model: Boolean,
        id: String,
        width: {
            type: String,
            default: '900'
        },
        removeVCardWrap: Boolean,
        persistent: Boolean,
        fullscreen: Boolean,
    },
    data() {
        return {}
    },
    created() {
    },
    computed: {
        componentId() {
            return this.id || 'dialog';
        },
        isOpen: {
            get() {
                return this.model
            },
            set() {
                // close dialog event
                this.$emit('dialogClosed', {componentId: this.componentId})
            }
        }
    },
    methods: {}
};
</script>

<style lang="scss" scoped>
.dialog-container {
    min-height: 180px;

    .basic {
        color: #dabd81;
    }

    .highlight {
        color: #fdbe01;
    }
}

@media only screen and (max-width: $sm) {
    .dialog-header {
        font-size: 90% !important;
    }
}

</style>

<style lang="scss">
.v-dialog {
    overflow: visible !important;
}
</style>
