import {apiServiceVue} from "@/services/apiService.vue";

export default class {

    /** @type {string} */
    #url = process.env.VUE_APP_ACCOUNT_URL;

    constructor() {
    }

    /**
     *
     * @param {string} walletAddress
     * @return {Promise<{avatar, type, username}>}
     */
    async fetch(walletAddress) {
        const response = await apiServiceVue
            .get(
                this.#url
                + 'wallet/'
                + walletAddress
            );
        return response.data;
    }

    /**
     *
     * @param {string} walletAddress
     * @returns {Promise<Object>}
     */
    async fetchProfile(walletAddress) {
        const response = await apiServiceVue
            .get(
                this.#url
                + 'profile/wallet/'
                + walletAddress
            );
        return response.data;
    }

};
