import axios from 'axios';
import store from '../store/index'

export const apiServiceVue = {

    /**
     * @param url String
     * @param queryParams Object
     * @return {Promise<AxiosResponse<any>>}
     */
    async get(url, queryParams = {}) {
        const config = {
            params: queryParams,
        };
        if (store && store.state.web3.address) {
            config.headers = {'Auth-wallet': store.state.web3.address}
        }

        return axios.get(url, config);
    },

    /**
     * @param url String
     * @param data Object
     * @return {Promise<AxiosResponse<any>>}
     */
    async post(url, data) {
        const config = {};
        if (store && store.state.web3.address) {
            config.headers = {'Auth-wallet': store.state.web3.address}
        }

        return axios.post(url, data, config);
    },

    /**
     * @deprecated use .get() or .post() functions
     * In fact it's requestJSON()
     */
    async request(url, method = 'GET', body = null) {
        let params = {
            method: method,
            // mode: 'no-cors',
            // cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json, text-plain, */*",
                'Access-Control-Allow-Origin': '*'
            },
        };
        if ('POST' === method) {
            params.body = JSON.stringify(body);
        }
        return await fetch(url, params)
            .then(function (response) {
                if (response.status === 200) {
                    return response.json();
                } else {
                    return null;
                }
            }).then(function (json) {
                return json;
            })
            .catch(function (error) {
                console.error(error + ' ' + url);
            });
    },

    /**
     * @deprecated
     * @param url
     * @return {Promise<Response>}
     */
    async getFullResponse(url) {
        let params = {
            method: 'GET',
            // mode: 'no-cors',
            // cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json, text-plain, */*",
                'Access-Control-Allow-Origin': '*'
            },
        };
        return await fetch(url, params)
            .catch(function (error) {
                console.error(error + ' ' + url);
            });
    }

};
