var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "web3-wallet-button" },
    [
      !_vm.isWalletConnected
        ? _c(
            "v-btn",
            {
              staticClass: "text-capitalize wallet-btn",
              attrs: { color: "primary" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  _vm.walletChooseDialogOpen = !_vm.walletChooseDialogOpen
                },
              },
            },
            [_vm._v(" Connect Web3 wallet ")]
          )
        : _vm._e(),
      !_vm.isWalletConnected
        ? _c(
            "dialog-box",
            {
              attrs: {
                title: "Connect Web3 Wallet",
                model: _vm.walletChooseDialogOpen,
                width: "auto",
              },
              on: {
                dialogClosed: function ($event) {
                  _vm.walletChooseDialogOpen = !_vm.walletChooseDialogOpen
                },
              },
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "d-flex flex-row justify-center align-center pt-10",
                      attrs: { md: "12" },
                    },
                    [
                      _c("h4", { staticClass: "text-center" }, [
                        _vm._v(" We are on "),
                      ]),
                      _c("img", {
                        staticClass: "ml-3",
                        attrs: {
                          src: "/img/bnb-logo.png",
                          alt: "Binance Smart Chain",
                        },
                      }),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mt-8 mr-2" },
                [
                  _c(
                    "v-col",
                    { attrs: { md: "6" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "text-capitalize wallet-btn",
                          attrs: { color: "primary", "x-large": "", block: "" },
                          on: {
                            click: function ($event) {
                              return _vm.connectMetamaskWallet()
                            },
                          },
                        },
                        [
                          _c(
                            "svg",
                            {
                              staticClass: "mr-2",
                              staticStyle: { height: "30px" },
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                viewBox: "0 0 318.6 318.6",
                                "stroke-linejoin": "round",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d: "M274.1 35.5l-99.5 73.9L193 65.8z",
                                  fill: "#e2761b",
                                  stroke: "#e2761b",
                                },
                              }),
                              _c(
                                "g",
                                {
                                  attrs: { fill: "#e4761b", stroke: "#e4761b" },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d: "M44.4 35.5l98.7 74.6-17.5-44.3zm193.9 171.3l-26.5 40.6 56.7 15.6 16.3-55.3zm-204.4.9L50.1 263l56.7-15.6-26.5-40.6z",
                                    },
                                  }),
                                  _c("path", {
                                    attrs: {
                                      d: "M103.6 138.2l-15.8 23.9 56.3 2.5-2-60.5zm111.3 0l-39-34.8-1.3 61.2 56.2-2.5zM106.8 247.4l33.8-16.5-29.2-22.8zm71.1-16.5l33.9 16.5-4.7-39.3z",
                                    },
                                  }),
                                ]
                              ),
                              _c("path", {
                                attrs: {
                                  d: "M211.8 247.4l-33.9-16.5 2.7 22.1-.3 9.3zm-105 0l31.5 14.9-.2-9.3 2.5-22.1z",
                                  fill: "#d7c1b3",
                                  stroke: "#d7c1b3",
                                },
                              }),
                              _c("path", {
                                attrs: {
                                  d: "M138.8 193.5l-28.2-8.3 19.9-9.1zm40.9 0l8.3-17.4 20 9.1z",
                                  fill: "#233447",
                                  stroke: "#233447",
                                },
                              }),
                              _c("path", {
                                attrs: {
                                  d: "M106.8 247.4l4.8-40.6-31.3.9zM207 206.8l4.8 40.6 26.5-39.7zm23.8-44.7l-56.2 2.5 5.2 28.9 8.3-17.4 20 9.1zm-120.2 23.1l20-9.1 8.2 17.4 5.3-28.9-56.3-2.5z",
                                  fill: "#cd6116",
                                  stroke: "#cd6116",
                                },
                              }),
                              _c("path", {
                                attrs: {
                                  d: "M87.8 162.1l23.6 46-.8-22.9zm120.3 23.1l-1 22.9 23.7-46zm-64-20.6l-5.3 28.9 6.6 34.1 1.5-44.9zm30.5 0l-2.7 18 1.2 45 6.7-34.1z",
                                  fill: "#e4751f",
                                  stroke: "#e4751f",
                                },
                              }),
                              _c("path", {
                                attrs: {
                                  d: "M179.8 193.5l-6.7 34.1 4.8 3.3 29.2-22.8 1-22.9zm-69.2-8.3l.8 22.9 29.2 22.8 4.8-3.3-6.6-34.1z",
                                  fill: "#f6851b",
                                  stroke: "#f6851b",
                                },
                              }),
                              _c("path", {
                                attrs: {
                                  d: "M180.3 262.3l.3-9.3-2.5-2.2h-37.7l-2.3 2.2.2 9.3-31.5-14.9 11 9 22.3 15.5h38.3l22.4-15.5 11-9z",
                                  fill: "#c0ad9e",
                                  stroke: "#c0ad9e",
                                },
                              }),
                              _c("path", {
                                attrs: {
                                  d: "M177.9 230.9l-4.8-3.3h-27.7l-4.8 3.3-2.5 22.1 2.3-2.2h37.7l2.5 2.2z",
                                  fill: "#161616",
                                  stroke: "#161616",
                                },
                              }),
                              _c("path", {
                                attrs: {
                                  d: "M278.3 114.2l8.5-40.8-12.7-37.9-96.2 71.4 37 31.3 52.3 15.3 11.6-13.5-5-3.6 8-7.3-6.2-4.8 8-6.1zM31.8 73.4l8.5 40.8-5.4 4 8 6.1-6.1 4.8 8 7.3-5 3.6 11.5 13.5 52.3-15.3 37-31.3-96.2-71.4z",
                                  fill: "#763d16",
                                  stroke: "#763d16",
                                },
                              }),
                              _c("path", {
                                attrs: {
                                  d: "M267.2 153.5l-52.3-15.3 15.9 23.9-23.7 46 31.2-.4h46.5zm-163.6-15.3l-52.3 15.3-17.4 54.2h46.4l31.1.4-23.6-46zm71 26.4l3.3-57.7 15.2-41.1h-67.5l15 41.1 3.5 57.7 1.2 18.2.1 44.8h27.7l.2-44.8z",
                                  fill: "#f6851b",
                                  stroke: "#f6851b",
                                },
                              }),
                            ]
                          ),
                          _vm._v(" MetaMask "),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { md: "6" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "text-capitalize wallet-btn",
                          attrs: { color: "primary", "x-large": "", block: "" },
                          on: {
                            click: function ($event) {
                              return _vm.connectWCWallet()
                            },
                          },
                        },
                        [
                          _c(
                            "svg",
                            {
                              staticClass: "mr-4",
                              staticStyle: { height: "22px" },
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                viewBox: "0 0 400 238",
                                version: "1.1",
                              },
                            },
                            [
                              _c(
                                "g",
                                {
                                  attrs: {
                                    id: "Page-1",
                                    stroke: "none",
                                    "stroke-width": "1",
                                    fill: "none",
                                    "fill-rule": "evenodd",
                                  },
                                },
                                [
                                  _c(
                                    "g",
                                    { attrs: { id: "walletconnect-logo-2" } },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d: "M79.4992542,46.5389641 C142.715504,-15.3550143 245.209294,-15.3550143 308.425541,46.5389641 L316.033719,53.9880033 C319.194531,57.0827021 319.194531,62.1002006 316.033719,65.1949006 L290.007661,90.6765804 C288.427255,92.2239311 285.86491,92.2239311 284.284504,90.6765804 L273.814748,80.4258162 C229.713513,37.2470301 158.211283,37.2470301 114.110047,80.4258162 L102.897805,91.4035389 C101.317397,92.9508883 98.755052,92.9508883 97.1746471,91.4035389 L71.1485898,65.9218591 C67.9877774,62.8271591 67.9877774,57.8096606 71.1485898,54.7149619 L79.4992542,46.5389641 Z M362.249885,99.2377722 L385.413126,121.916595 C388.573922,125.01128 388.573941,130.028748 385.413167,133.123453 L280.968512,235.385079 C277.807722,238.479801 272.683033,238.479836 269.522198,235.385159 C269.522185,235.385146 269.52217,235.385132 269.522157,235.385118 L195.393696,162.807065 C194.603493,162.03339 193.32232,162.03339 192.532117,162.807065 C192.532112,162.80707 192.532108,162.807076 192.532103,162.807079 L118.40522,235.385079 C115.24444,238.479809 110.119749,238.479861 106.958905,235.385192 C106.958887,235.385174 106.958867,235.385155 106.958847,235.385136 L2.51128675,133.12215 C-0.649524409,130.027451 -0.649524409,125.009953 2.51128675,121.915254 L25.6745697,99.2364692 C28.8353808,96.1417705 33.9600712,96.1417705 37.1208837,99.2364692 L111.250424,171.815537 C112.040627,172.589212 113.321798,172.589212 114.112002,171.815537 C114.112014,171.815525 114.112024,171.815514 114.112036,171.815505 L188.237861,99.2364692 C191.398601,96.1416993 196.523291,96.1415832 199.684176,99.2362107 C199.68422,99.2362542 199.684263,99.2362977 199.684307,99.2363413 L273.813749,171.815505 C274.603952,172.589179 275.885125,172.589179 276.675327,171.815505 L350.803571,99.2377722 C353.964384,96.1430722 359.089073,96.1430722 362.249885,99.2377722 Z",
                                          id: "WalletConnect",
                                          fill: "#3B99FC",
                                          "fill-rule": "nonzero",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" Wallet Connect "),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.isWalletConnected
        ? _c(
            "div",
            { staticClass: "wallet-balance" },
            [
              _c(
                "v-row",
                {
                  attrs: {
                    align: "center",
                    justify: "space-around",
                    "align-content": "space-around",
                  },
                },
                [
                  _c("div", { staticClass: "hidden-md-and-down" }, [
                    _c(
                      "div",
                      {
                        staticClass: "d-flex align-center justify-end",
                        staticStyle: { "min-width": "250px" },
                      },
                      [
                        _c(
                          "v-scroll-y-transition",
                          { attrs: { "hide-on-leave": "" } },
                          [
                            _vm.showWeb3Balance
                              ? _c("wallet-balance-web3")
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "v-scroll-y-reverse-transition",
                          { attrs: { "hide-on-leave": "" } },
                          [
                            !_vm.showWeb3Balance
                              ? _c("wallet-balance-virtual")
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "v-btn",
                          {
                            attrs: { icon: "", plain: "", small: "" },
                            on: { click: _vm.switchBalance },
                          },
                          [_c("v-icon", [_vm._v("mdi-cached")])],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mx-2 darken hidden-md-and-down",
                          attrs: {
                            to: { name: "BuyToken", params: {} },
                            color: "golden",
                            outlined: "",
                          },
                        },
                        [
                          _c("div", {
                            staticClass: "bg-menu_icon_buy_scrl mr-2",
                          }),
                          _vm._v(" Get SCRL "),
                        ]
                      ),
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            "open-on-hover": _vm.isXLarge,
                            light: "",
                            "offset-y": "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          { attrs: { large: _vm.isXLarge } },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-row",
                                          { attrs: { "no-gutters": "" } },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "address-short mr-5 d-flex align-center",
                                              },
                                              [
                                                _c("v-icon", [
                                                  _vm._v("mdi-wallet-outline"),
                                                ]),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f("shortAddress")(
                                                        _vm.web3.address
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ],
                                              1
                                            ),
                                            _c("v-icon", [
                                              _vm._v("mdi-chevron-down"),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            4080752965
                          ),
                        },
                        [
                          _c(
                            "v-list",
                            [
                              _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.disconnectWallet()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-list-item-title",
                                    [
                                      _c("v-icon", [_vm._v("mdi-exit-to-app")]),
                                      _vm._v(" Disconnect "),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }