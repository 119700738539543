import Vue from 'vue'
import Vuex from 'vuex'
import TokenContract from "../services/contracts/tokenContract";
import accountService from "@/services/accountService";
import VirtualWalletService from "@/services/virtualWalletService";
import BigNumber from "bignumber.js";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        web3: {
            balance: 0,
            isWalletConnected: false,
            address: null,
        },
        virtualWallet: {
            balance: 0
        },
        userAccount: {
            username: null,
            avatar: null,
            integrationName: null,
            permissions: [],
            restrictions: [],
            level: 0,
            experience: 0,
        }
    },
    mutations: {
        setUserWeb3(state, data) {
            state.web3 = data;
        },
        setUserAccount(state, data) {
            state.userAccount = data;
        },
        setVirtualWallet(state, data) {
            state.virtualWallet = data;
        },
    },
    actions: {
        startWeb3(context) {
            window.addEventListener('wallet-connected', (event) => {
                let userWeb3 = {
                    balance: false,
                    isWalletConnected: false,
                    address: null,
                }
                if (event.detail && event.detail.address) {
                    const balance = event.detail.balance;
                    const address = event.detail.address;
                    const eth = event.detail.eth;
                    const tokenContract = event.detail.tokenContract;

                    userWeb3 = {
                        balance: balance,
                        isWalletConnected: !!address,
                        address: address,
                        eth: eth,
                        tokenContract: tokenContract,
                    }

                    context.commit('setUserWeb3', userWeb3);
                    const loadedEvent = new CustomEvent('user-wallet-loaded');
                    window.dispatchEvent(loadedEvent);

                    context.dispatch('fetchUser').then();
                    context.dispatch('updateVirtualBalance').then();
                } else {
                    context.commit('setUserWeb3', userWeb3);
                }
            });
        },

        fetchUser(context) {
            const address = context.state.web3.address;
            let userAccount;
            // Fetch logged account info
            new accountService().fetch(address).then((acc) => {
                userAccount = acc;
                context.commit('setUserAccount', userAccount);
                const loadedEvent = new CustomEvent('user-account-loaded');
                window.dispatchEvent(loadedEvent);
            });
        },

        async updateWeb3Balance(context) {
            if (this.state.web3.isWalletConnected) {
                let web3 = this.state.web3;
                const tokenContract = new TokenContract(web3, web3.address);
                web3.balance = await tokenContract.getCurrentAccountBalance();
                context.commit('setUserWeb3', web3);
            }
        },

        async updateVirtualBalance(context) {
            if (this.state.web3.isWalletConnected) {
                const vWallet = this.state.virtualWallet;
                const vScrlBalance = await new VirtualWalletService().getVScrlBalance(this.state.web3.address);
                vWallet.balance = BigNumber(vScrlBalance.earned).minus(vScrlBalance.spent).toNumber();
                context.commit('setVirtualWallet', vWallet);
            }
        },
    },
    modules: {}
})
